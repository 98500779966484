/* eslint-disable react/display-name */
import React, { useCallback, useContext, useEffect } from 'react';
import { AppStateContext } from '../../store/AppStateProvider';
import { ComponentTypes } from '../../types';
import { conditionalArray } from '../../utils/misc';
import FormFieldset from '../FormFieldset';
import FormInputOption from '../FormInputOption';

import './form-radio-group.css';

// type OptionProps = ApiOption extends {
//   onChange(): void;
// }

// interface OptionProps extends ApiOption {
//   onChange(): void;
// }

const FormRadioGroup = (props: any) => {
  const {
    legend,
    name,
    options,
    type,
    searchParam,
    summaryLabel,
    value,
    onChange,
  } = props;

  const {
    state,
    formatPrice,
    setPendingRoute,
  } = useContext(AppStateContext);

  const classList = conditionalArray(
    ['FormRadioGroup__options grid', true],
    ['FormRadioGroup__options--yes-no', type === ComponentTypes.YES_NO],
  ).join(' ');

  const dispatchValues = useCallback((value: string | undefined, budgets?: BudgetRangeDict) => {
    const pipedriveField = (() => {
      switch (searchParam) {
        case 'boatType': {
          return 'boat_type';
        }

        case 'charterType': {
          return 'service_type';
        }

        default: {
          return null;
        }
      }
    })();

    onChange({
      budgets,
      name,
      label: legend,
      pipedriveField,
      searchParam,
      summaryLabel,
      type,
      value,
    });
  }, [name, legend, searchParam, type, summaryLabel, onChange]);

  const handleChangeEvent = useCallback((budgets: BudgetRangeDict) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatchValues(event.target.value, budgets);
  }, [dispatchValues]);

  useEffect(() => {
    if (options.some((option: any) => option.value === value)) {
      if (value && type === ComponentTypes.ROUTE_SWITCH) {
        setPendingRoute(value);
      }

      return;
    }

    dispatchValues(undefined);
  }, [options]);

  return (
    <div className={ classList }>
      {
        options.map((option: ApiOption, index: number) => {
          const { budgets } = option as ApiCharterTypeOption || {};

          const budget = budgets?.[state.userCurrency];

          return (
            <FormInputOption
              key={ index }
              type="radio"
              isChecked={ option.value === value }
              onChange={ handleChangeEvent(budgets) }
              { ...option }
            >
              {
                !!budget && (
                  <span className="FormInputOption__footer">
                    Budget { formatPrice(budget.from) }-{ formatPrice(budget.to) }
                  </span>
                )
              }
            </FormInputOption>
          );
        })
      }
    </div>
  );
};

export default FormFieldset(FormRadioGroup);
