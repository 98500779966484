import React, {
  KeyboardEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';

import ReactDatePicker from 'react-datepicker';
import format from 'date-fns/format';

import WidgetComboBoxInput from '../WidgetComboBox/WidgetComboBoxInput';

import 'react-datepicker/dist/react-datepicker.css';
import './react-date-picker-overrides.css';
import './widget-date-picker.css';

const WidgetDatePicker = (props: any) => {
  const {
    defaultDate,
    startDateLabel,
    endDateLabel,
    searchParam,
    availability,
    value,
    onChange,
  } = props;

  const [popupIsActive, setPopupIsActive] = useState(false);

  const [startDate, setStartDate] = useState<Date | undefined>(
    value?.from ? new Date(value.from) : undefined,
  );

  const [endDate, setEndDate] = useState<Date | undefined>(
    value?.to ? new Date(value.to) : undefined,
  );

  const minDate = useMemo(() => new Date(), []);

  const formattedStartDate = useMemo(() => {
    if (!startDate) return undefined;

    return format(startDate, 'd MMM yyyy');
  }, [startDate]);

  const formattedEndDate = useMemo(() => {
    if (!endDate) return undefined;

    return format(endDate, 'd MMM yyyy');
  }, [endDate]);

  const handleKeyDownEvents = useCallback((event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault();

        if (popupIsActive) return;

        if (event.altKey) {
          setPopupIsActive(true);
        }

        break;
      }

      case 'ArrowUp': {
        event.preventDefault();

        if (!popupIsActive) return;

        if (event.altKey) {
          setPopupIsActive(false);
        }

        break;
      }

      default: {
        break;
      }
    }
  }, [popupIsActive]);

  const dispatchValue = useCallback((start: Date, end: Date) => {
    const value = start && end && `${start}` !== `${end}`
      ? {
        from: format(start, 'yyyy-MM-dd'),
        to: format(end, 'yyyy-MM-dd'),
      }
      : undefined;

    const summaryStart = start && format(start, 'd MMM yyyy');
    const summaryEnd = end && format(end, 'd MMM yyyy');

    onChange({
      name: 'dates',
      searchParam,
      value,
      summaryValue: [summaryStart, summaryEnd],
    });
  }, [searchParam, onChange]);

  const handleChange = useCallback((dates: [Date, Date]): void => {
    const [start, end] = dates;

    setStartDate(start);

    if (`${start}` !== `${end}`) {
      setEndDate(end);
    }

    dispatchValue(start, end);
  }, [dispatchValue]);

  const togglePopup = useCallback((value: boolean) => () => {
    setPopupIsActive(value);
  }, []);

  const handleReset = useCallback(() => {
    setStartDate(undefined);
    setEndDate(undefined);

    onChange({
      name: 'dates',
      searchParam,
      value: undefined,
    });
  }, [searchParam, onChange]);

  return (
    <>
      <div className="WidgetDatePicker">
        <div className="WidgetDatePicker__fields-wrapper">
          <div className="WidgetDatePicker__fields grid">
            <WidgetComboBoxInput
              label={ startDateLabel }
              popupId={ `widget-datepicker-popup` }
              ariaExpanded={ popupIsActive }
              value={ formattedStartDate }
              onClick={ togglePopup(!popupIsActive) }
              onKeyDown={ handleKeyDownEvents }
            />
            <WidgetComboBoxInput
              label={ endDateLabel }
              popupId={ `widget-date-picker-popup` }
              ariaExpanded={ popupIsActive }
              value={ formattedEndDate }
              onClick={ togglePopup(!popupIsActive) }
              onKeyDown={ handleKeyDownEvents }
            />
          </div>
          {
            popupIsActive && (
              <div
                id="widget-date-picker-popup"
                role="dialog"
                aria-modal="true"
                className="WidgetDatePicker__popup"
              >
                <ReactDatePicker
                  calendarClassName="WidgetDatePicker__popup-calendar"
                  startDate={ startDate }
                  endDate={ endDate }
                  minDate={ minDate }
                  openToDate={ defaultDate }
                  tabIndex={ -1 }
                  onChange={ handleChange }
                  onClickOutside={ togglePopup(false) }
                  filterDate={ availability?.filter }
                  useWeekdaysShort
                  selectsRange
                  inline
                  fixedHeight
                  showDisabledMonthNavigation
                >
                  <div className="WidgetDatePicker__popup-footer">
                    <button
                      type="button"
                      onClick={ handleReset }
                      className="color-blue"
                    >
                      RESET
                    </button>
                    <button
                      type="button"
                      onClick={ togglePopup(false) }
                      className="color-blue"
                    >
                      DONE
                    </button>
                  </div>
                </ReactDatePicker>
              </div>
            )
          }
        </div>
        {
          !!availability?.notice && (
            <p className="WidgetDatePicker__notice">
              { availability.notice }
            </p>
          )
        }
      </div>
    </>
  );
};
// eslint-disable-next-line react/jsx-no-bind
// formatWeekDay={(day: string) => windowWidth <= 768 ? day.slice(0, 1) : day.slice(0, 3)}
// excludeDateIntervals={
//   [
//     {
//       start: new Date(1970, 1, 1),
//       end: new Date(Date.now() - 24 * 3600 * 1000)
//     }
//   ]
// }
// onClickOutside={ handleClickOutside }
// onKeyDown={ handleKeyDown }

export default WidgetDatePicker;
