import React, { MouseEventHandler, ReactElement } from 'react';

import './base-button.css';
import { conditionalArray } from '../../utils/misc';

interface ButtonProps {
  className?: string
  disabled?:  boolean;
  type?:      'button' | 'submit' | 'reset';
  children:   string | ReactElement;
  onClick:    MouseEventHandler<HTMLButtonElement>;
}

const BaseButton = (props: ButtonProps) => {
  const {
    children,
    className,
    disabled,
    type = 'button',
    onClick,
  } = props;

  const classList = conditionalArray(
    ['BaseButton button', true],
    [className, !!className],
  ).join(' ');

  return (
    <button
      type={ type }
      className={ classList }
      onClick={ onClick }
      disabled={ disabled }
    >
      { children }
    </button>
  );
};

export default BaseButton;
