/* eslint-disable react/display-name */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import getDay from 'date-fns/getDay';
import useComponent from '../../hooks/use-component';
import FormCheckboxGroup from '../FormCheckboxGroup';
import FormRadioGroup from '../FormRadioGroup';
import FormFieldInput from '../FormFieldInput';
import FormFieldSelect from '../FormFieldSelect';
import WidgetBudget from '../WidgetBudget';
import WidgetDatePicker from '../WidgetDatePicker';
import { FormStateContext } from '../../store/FormStateProvider';

import { conditionalArray } from '../../utils/misc';
import { ComponentTypes, StepTypes } from '../../types';
import { AppStateContext } from '../../store/AppStateProvider';
import { boatTypeMap, serviceTypeMap } from '../../data-sources/pipedrive-value-maps';

import './form-step.css';

const FormStep = (props: ApiFormStep) => {
  const {
    legend,
    subheading,
    fields,
    type: stepType,
  } = props;

  const {
    setPendingRoute,
    updateRequiredFields,
    initBudgetRanges,
    getAvailabilityRule,
  } = useContext(AppStateContext);

  const {
    state: formState,
    setValue,
    setPipedriveData,
    setSearchParam,
    setSlackData,
    setSummaryData,
    updateList,
  } = useContext(FormStateContext);

  const Component = useComponent({
    FormCheckboxGroup,
    FormRadioGroup,
    FormFieldInput,
    FormFieldSelect,
    WidgetBudget,
    WidgetDatePicker,
  });

  const fieldsWrapperClassList = conditionalArray(
    ['FormStep__fields', true],
    ['FormStep__fields--fieldset', stepType === StepTypes.FIELDSET],
    ['FormStep__fields--passengers', stepType === StepTypes.PASSENGERS],
    ['FormStep__fields--contact', stepType === StepTypes.CONTACT],
  ).join(' ');

  const dispatchValue = (payload: any) => {
    const {
      budgets,
      label,
      name,
      pipedriveField,
      searchParam,
      summaryLabel,
      // summaryValue,
      type,
      value,
    } = payload;

    let {
      summaryValue,
    } = payload;

    const slackValue = stepType !== StepTypes.OPTION_GROUP
      ? { name: label, value }
      : value;

    setValue({ name, value });
    setSlackData({ name: legend, value: slackValue });

    if (searchParam) {
      const searchValue = (() => {
        switch (searchParam) {
          case 'search': {
            return { title: value, taxonomy: name };
          }

          case 'price': {
            return [0, value];
          }

          default: {
            return value;
          }
        }
      })();

      setSearchParam({ name: searchParam, value: searchValue });
    }

    if (summaryLabel) {
      summaryValue = stepType === StepTypes.PASSENGERS
        ? { [label]: `${value} ${label}` }
        : summaryValue;

      setSummaryData({ name: summaryLabel, value: summaryValue ?? value });
    }

    if (pipedriveField) {
      const pipedriveValue = (() => {
        switch (pipedriveField) {
          case 'service_type': {
            return serviceTypeMap.get(value);
          }

          case 'boat_type': {
            return boatTypeMap.get(value);
          }

          default: {
            return value;
          }
        }
      })();

      setPipedriveData({ name: pipedriveField, value: pipedriveValue });
    }

    if (budgets) {
      initBudgetRanges(budgets);
    }

    if (value && type === ComponentTypes.ROUTE_SWITCH) {
      setPendingRoute(value);
    }
  };

  const dispatchListValue = (payload: any) => {
    const {
      label,
      name,
      searchParam,
      summaryLabel,
      value,
    } = payload;

    const slackValue = stepType !== StepTypes.OPTION_GROUP
      ? { name: label, value: [value] }
      : [value];

    updateList({ name, value });
    setSlackData({ name: legend, value: slackValue });

    if (searchParam) {
      setSearchParam({ name: searchParam, value });
    }

    if (summaryLabel) {
      setSummaryData({ name: summaryLabel, value: [value] });
    }
  };

  const dispatchDatePickerValue = (payload: any) => {
    const {
      name,
      searchParam,
      value,
      summaryValue: [
        summaryStart,
        summaryEnd,
      ] = [],
    } = payload;

    setValue({ name, value });
    setSearchParam({ name: searchParam, value });
    setPipedriveData({ name: 'start_date', value: value?.from });
    setPipedriveData({ name: 'end_date', value: value?.to });
    setSummaryData({ name: 'Set Sail On', value: summaryStart });
    setSummaryData({ name: 'Return On', value: summaryEnd });
    setSlackData({ name: legend, value: `${summaryStart} - ${summaryEnd}` });
  };

  const dispatchBudgetValue = (payload: any) => {
    const {
      name,
      searchParam,
      value,
      pipedriveField,
      slackValue,
      summaryValue,
    } = payload;

    const searchValue = [0, value];

    if (searchParam) {
      setSearchParam({ name: searchParam, value: searchValue });
    }

    if (slackValue) {
      setSlackData({ name: legend, value: slackValue });
    }

    if (summaryValue) {
      setSummaryData({ name: 'Budget Estimation', value: summaryValue });
    }

    setValue({ name, value });
    setPipedriveData({ name: pipedriveField, value });
  };

  const dispatcher = useCallback((fieldName: string) => (payload: any) => {
    switch (fieldName) {
      case 'FormRadioGroup':
      case 'FormFieldSelect':
      case 'FormFieldInput': {
        dispatchValue(payload);

        break;
      }

      case 'FormCheckboxGroup': {
        dispatchListValue(payload);

        break;
      }

      case 'WidgetDatePicker': {
        dispatchDatePickerValue(payload);

        break;
      }

      case 'WidgetBudget': {
        dispatchBudgetValue(payload);

        break;
      }

      default: {
        break;
      }
    }
  }, [fields]);

  useEffect(() => {
    fields.forEach((field: any) => {
      const { required, name } = field.props;

      if (!required || typeof formState[name] !== 'undefined') return;

      updateRequiredFields(name);
    });
  }, [fields, formState]);

  const availability = useMemo(() => {
    const rule = getAvailabilityRule(
      formState.country as string,
      formState.location as string,
    );

    if (!rule) return null;

    return {
      ...rule,
      filter(date: Date) {
        const day = getDay(date);
        const { checkInDayNumeric } = rule;

        return day === checkInDayNumeric;
      },
    };
  }, [formState.country, formState.location, getAvailabilityRule]);

  const memoisedFieldComponents = useCallback((state: FormState) => {
    return fields.map((field: ApiComponent, index: number) => {
      let fieldProps: any = {
        ...field.props,
        value: state[field.props.name],
        onChange: dispatcher(field.name),
      };

      switch (field.name) {
        case 'WidgetDatePicker': {
          if (state?.['charter-type'] !== 'crewed') {
            fieldProps = {
              ...fieldProps,
              availability,
            };
          }

          break;
        }

        case 'FormFieldSelect': {
          fieldProps = {
            ...fieldProps,
            autoInitValue: stepType === StepTypes.PASSENGERS,
          };

          break;
        }

        default: {
          break;
        }
      }

      return (
        <Component
          key={ `${field.name}-${index}` }
          is={ field.name }
          { ...fieldProps }
        />
      );
    });
  }, [fields]);

  return (
      <fieldset className="FormStep">
        <div className="FormStep__heading">
          <legend className="FormStep__legend text-h1">
            { legend?.replace(/\\n/g, '\n') }
          </legend>
          {
            !!subheading && (
              <p>
                { subheading }
              </p>
            )
          }
        </div>
        <div className={ fieldsWrapperClassList }>
          { memoisedFieldComponents(formState) }
        </div>
      </fieldset>
  );
};

export default FormStep;
