import LinkedList from './LinkedList';

class Stack<T> implements Arrayable<T> {
  #_entries;

  #_stackPointer = 0;

  public constructor() {
    this.#_entries = new LinkedList<T>();
  }

  public push(value: T) {
    this.#_entries.insertFront(value);
  }

  public pop(): T | undefined {
    return this.#_entries.removeHead();
  }

  public rebaseToPointer(): T[] {
    const poppedEntries: T[] = [];

    if (this.#_stackPointer === this.size) {
      return poppedEntries;
    }

    for (let i = 0; i < this.#_stackPointer; i++) {
      poppedEntries.push(this.pop() as T);
    }

    this.#_stackPointer = 0;

    return poppedEntries;
  }

  public peek(): T | undefined {
    return this.#_entries.at(this.#_stackPointer);
  }

  public incrementPointer(): void {
    if (this.#_stackPointer + 1 > this.size) {
      return;
    }

    this.#_stackPointer++;
  }

  public decrementPointer(): void {
    if (this.#_stackPointer - 1 < 0) {
      return;
    }

    this.#_stackPointer--;
  }

  public toArray(): T[] {
    return this.#_entries.toArray();
  }

  public get size(): number {
    return this.#_entries.size;
  }

  public get isPointerAtTop(): boolean {
    return this.#_stackPointer === 0;
  }

  public get isPointerAtBottom(): boolean {
    return this.#_stackPointer === this.size - 1;
  }

  public set stackPointer(value: number) {
    if (value < 0 || value > this.size) {
      throw new RangeError('The stack pointer cannot be less than zero or greater than the stack size.');
    }

    this.#_stackPointer = value;
  }

  // eslint-disable-next-line class-methods-use-this
  public get [Symbol.toStringTag]() {
    return 'Stack';
  }
}

export default Stack;
