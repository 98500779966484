import React, {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import createRequest from '../hooks/use-fetch';
import { defineAction } from '../utils/state';
import StepRouter from '../router/StepRouter';
import appStateReducer, { AppStateActions } from './app-state-reducer';
// import router from '../router/step-router';

const initialState: AppState = {
  userCountryCode:       '',
  userCurrency:          '',
  availabilityRules:     [],
  exchangeRates:         {},
  availableBudgetRanges: {},
  currencyCodes:         [],
  requiredFields:        [],
  isTransitioning:       false,
  pendingRoutePath:      null,
  router:                new StepRouter(),

  budgetRange: {
    from: 0,
    to:   1000000,
  },
};

interface AppContext {
  state:                 AppState;
  currentStep:           ApiFormStep;
  router:                Router & Emitter;
  setPendingRoute:       React.Dispatch<string | null>;
  pushRoute:             React.Dispatch<string>;
  nextStep:              PartialParams<React.Dispatch<unknown>>;
  historyBack:           PartialParams<React.Dispatch<unknown>>;
  historyForward:        PartialParams<React.Dispatch<unknown>>;
  setRequiredField:      React.Dispatch<string>;
  setIsTransitioning:    React.Dispatch<boolean>;
  updateUserCurrency:    React.Dispatch<string>;
  initBudgetRanges:      React.Dispatch<BudgetRangeDict>;
  updateBudgetRange:     React.Dispatch<string>;
  updateRequiredFields:  React.Dispatch<any>; // React.Dispatch<string | string[]>;
  formatPrice:           (value: number, options?: any) => string;
  getAvailabilityRule:   (...locations: string[]) => AvailabilityRule | undefined;
}

const AppStateContext = createContext({} as AppContext);

const AppStateProvider = ({ children }: { children: ReactElement }) => {
  const [appState, dispatchAppState] = useReducer(appStateReducer, initialState);

  useEffect(() => {
    const setAppStateFromWindowMessage = ({ data }: { data: any }) => {
      const { source, type, payload } = data;

      if (source !== 'HELM') return;

      switch (type) {
        case 'INIT': {
          dispatchAppState({
            type: AppStateActions.INIT_USER_DATA,
            payload,
          });

          break;
        }

        // case 'COUNTRY_CODE': {
        //   dispatchAppState({
        //     type: AppStateActions.SET_COUNTRY_CODE,
        //     payload: payload.countryCode,
        //   });

        //   return;
        // }

        case 'CURRENCY': {
          dispatchAppState({
            type: AppStateActions.SET_CURRENCY,
            payload: payload.currency,
          });

          break;
        }

        default: {
          throw new Error(`No message expected with type '${type}'`);
        }
      }

      window.removeEventListener('message', setAppStateFromWindowMessage);
    };

    window.addEventListener('message', setAppStateFromWindowMessage);

    const fetchApiData = async () => {
      const uri = `${process.env.REACT_APP_HELM_BACKEND_API_URL}/api/enquiry-builder`;

      const request = createRequest(uri);

      const data: ApiTreeNode = await request.send();

      if (data === null) return;

      dispatchAppState({ type: AppStateActions.INIT_DATA, payload: data });
    };

    fetchApiData();
  }, []);

  const currencyFormatter = useMemo(() => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: appState.userCurrency || 'GBP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }, [appState.userCurrency]);

  const formatPrice = useCallback((value: number, options: { compact?: boolean }) => {
    const { compact = false } = options || {};

    if (compact) {
      const compactPrice = value / 1000 >= 1000
        ? value / 1000000
        : value / 1000;

      const unit = value / 1000 >= 1000 ? 'M' : 'K';

      return `${currencyFormatter.format(compactPrice)}${unit}`;
    }

    return currencyFormatter.format(value);
  }, [appState.userCurrency, currencyFormatter]);

  const getAvailabilityRule = useCallback((...locations: string[]): AvailabilityRule | undefined => {
    return appState.availabilityRules
      .find((rule) => {
        return locations.some((location) => rule.locations.includes(location));
      });
  }, [appState.availabilityRules]);

  const providerContext: AppContext = {
    state:                appState,
    currentStep:          appState.router.currentStep as ApiFormStep,
    router:               appState.router,
    pushRoute:            defineAction(AppStateActions.PUSH_ROUTE, dispatchAppState),
    nextStep:             defineAction(AppStateActions.NEXT_STEP, dispatchAppState),
    historyBack:          defineAction(AppStateActions.HISTORY_BACK, dispatchAppState),
    historyForward:       defineAction(AppStateActions.HISTORY_FORWARD, dispatchAppState),
    setRequiredField:     defineAction(AppStateActions.SET_REQUIRED_FIELD, dispatchAppState),
    setIsTransitioning:   defineAction(AppStateActions.SET_IS_TRANSITIONING, dispatchAppState),
    updateRequiredFields: defineAction(AppStateActions.UPDATE_REQUIRED_FIELDS, dispatchAppState),
    updateUserCurrency:   defineAction(AppStateActions.UPDATE_USER_CURRENCY, dispatchAppState),
    initBudgetRanges:     defineAction(AppStateActions.INIT_BUDGET_RANGES, dispatchAppState),
    updateBudgetRange:    defineAction(AppStateActions.UPDATE_BUDGET_RANGE, dispatchAppState),
    setPendingRoute:      defineAction(AppStateActions.SET_PENDING_ROUTE, dispatchAppState),
    formatPrice,
    getAvailabilityRule,
  };

  return (
    <AppStateContext.Provider value={ providerContext }>
      { children }
    </AppStateContext.Provider>
  );
};

// eslint-disable-next-line no-restricted-exports
export { AppStateProvider as default, AppStateContext };
