import React, { useCallback, useEffect } from 'react';
import { IconChevron } from '../Icons';

import './form-field-select.css';

const FormFieldSelect = (props: any) => {
  const {
    name,
    label,
    options,
    placeholder,
    pipedriveField,
    searchParam,
    summaryLabel,
    type,
    value,
    autoInitValue,
    onChange,
  } = props;

  const handleChangeEvent = useCallback((
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const value = type === 'Range'
      ? Number(event.target.value)
      : event.target.value;

    const summaryValue = (() => {
      if (!summaryLabel) {
        return undefined;
      }

      if (type === 'Comparison') {
        return options.find((option: ApiBaseOption) => option.value === value)?.label;
      }

      return value;
    })();

    onChange({
      name,
      label,
      pipedriveField,
      searchParam,
      summaryLabel,
      summaryValue,
      type,
      value,
    });
  }, [
    label,
    name,
    pipedriveField,
    searchParam,
    summaryLabel,
    type,
    onChange,
    options,
  ]);

  useEffect(() => {
    if (!autoInitValue || value) return;

    const { value: firstOptionValue } = options[0];

    const initialValue = type === 'Range'
      ? Number(firstOptionValue)
      : firstOptionValue;

    onChange({
      name,
      label,
      pipedriveField,
      type,
      summaryLabel,
      value: initialValue,
    });
  }, []);

  return (
    <div className="FormFieldSelect">
      <div className="FormFieldSelect__inner field">
        <label
          id={ `select-field-${name}` }
          className="FormFieldSelect__label field__label color-grey-darker"
        >
          { label }
        </label>
        <span className="FormFieldSelect__select-wrapper">
          <select
            id={ `select-field-${name}` }
            className="FormFieldSelect__input field__input"
            value={ value }
            onChange={ handleChangeEvent }
          >
            {
              !!placeholder && (
                <option hidden>
                  { placeholder }
                </option>
              )
            }
            {
              options.map((option: ApiBaseOption) => (
                <option
                  key={ option.label }
                  value={ option.value }
                >
                  { option.label }
                </option>
              ))
            }
          </select>
          <span className="FormFieldSelect__icon">
            <IconChevron />
          </span>
        </span>
      </div>
    </div>
  );
};

export default FormFieldSelect;
