const activeRequests = new Map();

class RequestWrapper {
  public uri: RequestInfo | URL;

  public options: RequestInit | undefined;

  public sent = false;

  #abortController: AbortController;

  constructor(uri: RequestInfo | URL, options: RequestInit | undefined) {
    this.#abortController = new AbortController();

    this.uri = uri;
    this.options = options;
  }

  async send() {
    if (!this.#abortController.signal.aborted) {
      this.abort();
    }

    this.#abortController = new AbortController();

    try {
      return (
        await fetch(this.uri, {
          ...this.options,
          signal: this.#abortController.signal,
        })
      ).json();
    } catch (err: any) {
      if (err.name === 'AbortError') {
        return null;
      }

      throw err;
    } finally {
      activeRequests.delete(this.uri);
    }
  }

  abort() {
    this.#abortController.abort();
  }
}

const createRequest = (
  uri: RequestInfo | URL,
  options: RequestInit | undefined = {},
) => {
  if (activeRequests.has(uri)) {
    return activeRequests.get(uri);
  }

  const request = new RequestWrapper(uri, options);

  activeRequests.set(uri, request);

  return request;
};

export default createRequest;
