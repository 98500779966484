export const enum StepTypes {
  BUDGET       = 'BUDGET',
  CONTACT      = 'CONTACT',
  FIELDSET     = 'FIELDSET',
  OPTION_GROUP = 'OPTION_GROUP',
  PASSENGERS   = 'PASSENGERS',
  THANK_YOU    = 'THANK_YOU',
}

export const enum ComponentTypes {
  ROUTE_SWITCH = 'ROUTE_SWITCH',
  YES_NO       = 'YES_NO',
}
