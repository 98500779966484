import Emitter from '../data-structures/Emitter';
import Stack from '../data-structures/Stack';
import StackSet from '../data-structures/StackSet';
import { prop } from '../utils/functional';
import { forEachKey } from '../utils/iteration';
import { toBinary } from '../utils/misc';

type RouteTree = ApiTreeNode | TreeRootNode;

interface ActiveRoute {
  path:  string;
  steps: ApiFormStep[];
}

interface HistoryEntry {
  path: string;
  step: ApiFormStep;
}

/**
 * These bitmasks are combined to represent the current state of
 * the router. This means the state at any point can be represented
 * as a single 6-bit number rather than seven separate boolean fields.
 * e.g. 0b001011 (or 11 in decimal) would mean the router is initialised,
 * at the end of a route and updating. This can be represented as...
 *
 * RouterFlags.INITIALISED | RouterFlags.ROUTE_END | RouterFlags.UPDATING
 */
const RouterFlags = {
  UNINITIALISED: 0b000000, // Default state before initialisation
  INITIALISED:   0b000001, // Set upon initialisation and remains set for the duration of the app
  UPDATING:      0b000010, // Set at the end of a method and used to prevent the method being called twice
  ROUTE_START:   0b000100, // Set when on the first step of a route
  ROUTE_END:     0b001000, // Set when on the last step of a route
  ROUTE_PENDING: 0b010000, // Set when the next route is pending, but 'next' hasn't been clicked
  HISTORY_MODE:  0b100000, // Set when the back/forward navigation in the bottom corner is used
} as const;

// const findShortestPath = (tree: ApiTreeNode) => {
//   let shortestPath: number | undefined;

//   function* traverseTree(tree: ApiTreeNode, count = 0): any {
//     console.log(tree);
//     console.log('steps', tree.steps.length + count);

//     if (Object.keys(tree.nodes).length) {
//       for (const node of Object.values(tree.nodes) as any) {
//         yield* traverseTree(node, count + tree.steps.length);
//       }

//       return;
//     }

//     yield count;
//   }

//   for (const node of traverseTree(tree)) {
//     if (typeof shortestPath === 'undefined' || node < shortestPath) {
//       shortestPath = node;
//     }
//   }

//   console.log('shortest path', shortestPath);
// };

const DEBUG = false;

class StepRouter extends Emitter {
  #_tree?:         RouteTree;

  #_pathStack:     StackSet<string>;

  #_trackedSteps:  Map<string, number>;

  #_currentRoute?: ActiveRoute;

  #_historyStack:  Stack<HistoryEntry>;

  #_state:         number;

  public constructor() {
    super();

    this.#_state = RouterFlags.UNINITIALISED;

    this.#_pathStack = new StackSet();
    this.#_trackedSteps = new Map();
    this.#_historyStack = new Stack();
  }

  public init(treeData: ApiTreeNode): void {
    if (DEBUG) {
      console.groupCollapsed('[DEBUG] RouterFlags');

      forEachKey(RouterFlags, (key) => {
        console.log(`${key}:`.padEnd(14, ' '), toBinary(RouterFlags[key as keyof typeof RouterFlags]));
      });

      console.groupEnd();
    }

    if (this.#_isFlagSet(RouterFlags.INITIALISED | RouterFlags.ROUTE_END)) {
      return;
    }

    this.#_tree = {
      nodes: {
        root: treeData,
      },
    };

    console.log(this.#_tree);

    // findShortestPath(this.#_tree.nodes.root);

    this.#_historyStack.push({
      path: 'root',
      step:  treeData.steps[0] as ApiFormStep,
    });

    this.push('root');

    this.#_setFlag(RouterFlags.INITIALISED);
  }

  /**
   * Pushes the pending route path to the stack so it's ready to be switched to in the following 'next' call
   *
   * @returns {void}
   */
  public push(path: string) {
    if (DEBUG) {
      console.groupCollapsed('[DEBUG] StepRouter::push');

      console.log('path:', path);
      console.log('state:', toBinary(this.#_state));
    }

    if (this.#_isFlagSet(RouterFlags.UPDATING)) {
      if (DEBUG) {
        console.log('RouterFlags.UPDATING is set. Returning');

        console.groupEnd();
      }

      return;
    }

    if (this.#_isFlagSet(RouterFlags.INITIALISED)) {
      if (DEBUG) {
        console.log('RouterFlags.INITIALISED is set');
      }

      this.#_setFlag(RouterFlags.ROUTE_PENDING);

      if (DEBUG) {
        console.log('RouterFlags.ROUTE_PENDING set');
        console.log('state:', toBinary(this.#_state));
      }
    }

    if (DEBUG) {
      console.log(`Triggering 'route-push' hook`);
    }

    this.emit('route-push');

    if (this.#_isFlagSet(RouterFlags.HISTORY_MODE)) {
      this.#_rebaseHistory(path);
    }

    const currentRoute = this.#_pathStack
      .toArray()
      .reduce((route: RouteTree, path: string) => {
        return route.nodes[path];
      }, this.#_tree as ApiTreeNode);

    if (!(path in currentRoute.nodes)) {
      if (DEBUG) {
        console.log(`No child route exists for '${path}'. Returning.`);

        console.groupEnd();
      }

      return;
    }

    // Pops the pending route from the path stack if another route is selected before 'next'
    // is called. Only runs when 'root' isn't pending to avoid breaking out of the tree.
    if (this.#_isFlagSet(RouterFlags.ROUTE_START) && this.#_pathStack.peek() !== 'root') {
      if (DEBUG) {
        console.log('RouterFlags.ROUTE_START is set');
        console.log('Popping currentPendingRoute from top of this.#_pathStack');
      }

      const currentPendingRoute = this.#_pathStack.pop();

      if (DEBUG) {
        console.log('currentPendingRoute', currentPendingRoute);
        console.log('this.#_pathStack:', this.#_pathStack);
      }

      if (currentPendingRoute) {
        this.#_trackedSteps.delete(currentPendingRoute);

        if (DEBUG) {
          console.log(`currentPendingRoute deleted from this.#_trackedSteps`);
          console.log('this.#_trackedSteps:', this.#_trackedSteps);
        }
      }
    }

    if (DEBUG) {
      console.log(`Pushing '${path}' to this.#_pathStack`);
      console.log(`Adding '${path}' to this.#_trackedSteps`);
    }

    this.#_pathStack.push(path);
    this.#_trackedSteps.set(path, 0);

    if (DEBUG) {
      console.log('this.#_pathStack', this.#_pathStack);
      console.log('this.#_trackedSteps', this.#_trackedSteps);
    }

    this.#_setFlag(RouterFlags.ROUTE_START | RouterFlags.UPDATING);

    if (DEBUG) {
      console.log('RouterFlags.ROUTE_START | RouterFlags.UPDATING set');
      console.log('state:', toBinary(this.#_state));

      console.groupEnd();
    }

    setTimeout(() => {
      this.#_clearFlag(RouterFlags.UPDATING);

      if (DEBUG) {
        console.groupCollapsed('[DEBUG] StepRouter::push - After Timeout');

        console.log('RouterFlags.UPDATING cleared');
        console.log('state:', toBinary(this.#_state));

        console.groupEnd();
      }
    });
  }

  /**
   * Advances to the next step in the route tree
   *
   * @returns {void}
   */
  public next(): void {
    if (DEBUG) {
      console.groupCollapsed('[DEBUG] StepRouter::next');

      console.log('state:', toBinary(this.#_state));
    }

    if (this.#_isFlagSet(RouterFlags.UPDATING)) {
      if (DEBUG) {
        console.log('RouterFlags.UPDATING is set. Returning');

        console.groupEnd();
      }

      return;
    }

    if (DEBUG) {
      console.log(`Triggering 'next-step' hook`);
    }

    this.emit('next-step');

    this.#_clearFlag(RouterFlags.ROUTE_PENDING);

    if (DEBUG) {
      console.log('RouterFlags.ROUTE_PENDING cleared.');
      console.log('state:', toBinary(this.#_state));
    }

    if (this.#_isFlagSet(RouterFlags.HISTORY_MODE)) {
      if (this.isHistoryForwardEnabled) {
        this.forward();

        if (DEBUG) {
          console.groupEnd();
        }

        return;
      }

      this.#_clearFlag(RouterFlags.HISTORY_MODE);
    }

    if (this.#_isFlagSet(RouterFlags.ROUTE_END)) {
      if (DEBUG) {
        console.log('RouterFlags.ROUTE_END is set. Exiting Route.');
      }

      this.#_exitRoute();
      this.#_clearFlag(RouterFlags.ROUTE_END);

      if (DEBUG) {
        console.log('RouterFlags.ROUTE_END cleared.');
        console.log('state:', toBinary(this.#_state));
      }
    }

    const currentPath = this.#_pathStack.peek();

    if (DEBUG) {
      console.log('currentPath:', currentPath);
    }

    if (!currentPath) {
      if (DEBUG) {
        console.log('currentPath is undefined. Returning.');

        console.groupEnd();
      }

      return;
    }

    const currentRoute = this.#_pathStack
      .toArray()
      .reduce((route: RouteTree, path: string) => {
        return route.nodes[path];
      }, this.#_tree as ApiTreeNode);

    if (DEBUG) {
      console.log('currentRoute:', currentRoute);
    }

    if (!currentRoute) {
      if (DEBUG) {
        console.log('Popping top of this.#_pathStack');
        console.log(`Deleteing '${currentPath}' from this.#_trackedSteps`);
      }

      this.#_pathStack.pop();
      this.#_trackedSteps.delete(currentPath);

      if (DEBUG) {
        console.log('current route is undefined');
        console.log('this.#_pathStack', this.#_pathStack);
        console.log('this.#_trackedSteps', this.#_trackedSteps);
      }

      if (this.#_isFlagSet(RouterFlags.UPDATING)) {
        if (DEBUG) {
          console.log('RouterFlags.UPDATING is set from pushed route.');
        }

        // this.#_clearFlag(RouterFlags.UPDATING);

        if (DEBUG) {
          console.log('RouterFlags.UPDATING cleared');
          console.log('state:', toBinary(this.#_state));
        }
      }

      this.#_clearFlag(RouterFlags.ROUTE_PENDING | RouterFlags.ROUTE_START);

      if (DEBUG) {
        console.log('RouterFlags.ROUTE_PENDING | RouterFlags.ROUTE_START cleared.');
        console.log('state:', toBinary(this.#_state));
        console.log('Calling next and returning.');

        console.groupEnd();
      }

      this.next();

      return;
    }

    const currentStepIndex = this.#_trackedSteps.get(currentPath) as number;

    if (DEBUG) {
      console.log('currentStepIndex:', currentStepIndex);
    }

    if (!this.#_isFlagSet(RouterFlags.ROUTE_START) || currentPath === 'root') {
      this.#_trackedSteps.set(currentPath, currentStepIndex + 1);

      if (DEBUG) {
        console.log('Incrementing tracked step index for current path', currentPath);
        console.log('currentStepIndex:', currentStepIndex + 1);
      }
    }

    this.#_setFlag(RouterFlags.UPDATING);

    const newHistoryItem = {
      path: currentPath,
      step: this.currentStep as ApiFormStep,
    };

    if (DEBUG) {
      console.log('RouterFlags.UPDATING set');
      console.log('state:', toBinary(this.#_state));
    }

    this.#_historyStack.push(newHistoryItem);

    if (DEBUG) {
      console.log('New history item pushed to stack', newHistoryItem);
      console.log('this.#_historyStack', this.#_historyStack);

      console.groupEnd();
    }

    setTimeout(() => {
      this.#_clearFlag(RouterFlags.UPDATING | RouterFlags.ROUTE_START);

      if (DEBUG) {
        console.groupCollapsed('[DEBUG] StepRouter::next - After Timeout');

        console.log('RouterFlags.UPDATING | RouterFlags.ROUTE_START cleared');
        console.log('state:', toBinary(this.#_state));

        console.groupEnd();
      }
    });
  }

  public back(): void {
    if (this.#_isFlagSet(RouterFlags.UPDATING)) {
      return;
    }

    this.#_setFlag(RouterFlags.UPDATING | RouterFlags.HISTORY_MODE);

    this.#_historyStack.incrementPointer();

    this.emit('history-back');

    setTimeout(() => {
      this.#_clearFlag(RouterFlags.UPDATING);
    });
  }

  public forward(): void {
    if (this.#_isFlagSet(RouterFlags.UPDATING)) {
      return;
    }

    this.#_setFlag(RouterFlags.UPDATING | RouterFlags.HISTORY_MODE);

    // this.emit('before-history-forward');

    this.#_historyStack.decrementPointer();

    this.emit('history-forward');

    setTimeout(() => {
      this.#_clearFlag(RouterFlags.UPDATING);
    });
  }

  /**
   * Called when at the end of a route. Steps out of the
   * current route and continues in the next step from
   * where was left off in the previous route.
   *
   * @returns {void}
   */
  #_exitRoute(): void {
    if (!this.currentRoute) return;

    const { path, steps } = this.currentRoute;

    let routeSteps = steps.length;
    let routeStepIndex = this.#_trackedSteps.get(path) as number;

    while (routeStepIndex + 1 >= routeSteps) {
      this.#_pathStack.pop();

      const route = this.currentRoute;

      routeSteps = route.steps.length;
      routeStepIndex = this.#_trackedSteps.get(route.path) as number;
    }
  }

  /**
   * Called when switching routes after navigating using
   * history mode. Clears the current history beyond the
   * stack pointer, and sets the router state as if it's
   * starting fresh from the current step.
   *
   * @returns {void}
   */
  #_rebaseHistory(path: string): void {
    if (DEBUG) {
      console.groupCollapsed('[DEBUG] StepRouter::#_rebaseHistory');
    }

    this.#_clearFlag(RouterFlags.HISTORY_MODE | RouterFlags.ROUTE_END);

    const historyBeforeRebase = this.#_historyStack.toArray();

    if (DEBUG) {
      console.log('historyBeforeRebase', historyBeforeRebase);
    }

    this.emit('before-rebase', historyBeforeRebase);

    const poppedEntries = this.#_historyStack.rebaseToPointer();

    if (DEBUG) {
      console.log('Rebased this.#_historyStack to pointer');
      console.log('poppedEntries:', poppedEntries);
    }

    let lastPath = '';

    poppedEntries.forEach((entry) => {
      const trackedSteps = this.#_trackedSteps.get(entry.path);

      lastPath = entry.path;

      if (typeof trackedSteps === 'undefined') {
        return;
      }

      if (trackedSteps === 0) {
        this.#_trackedSteps.delete(entry.path);

        if (!this.#_trackedSteps.has(this.#_pathStack.peek() as string)) {
          this.#_pathStack.pop();
        }

        return;
      }

      this.#_trackedSteps.set(entry.path, trackedSteps - 1);
    });

    if (DEBUG) {
      console.log('lastPath:', lastPath);
    }

    const currentRoute = this.#_pathStack
      .toArray()
      .reduce((route: RouteTree, path: string) => {
        return route.nodes[path];
      }, this.#_tree as ApiTreeNode);

    if (DEBUG) {
      console.log('currentRoute', currentRoute);
    }

    if (currentRoute) {
      this.#_currentRoute = {
        path: this.#_pathStack.peek() as string,
        steps: currentRoute.steps,
      };

      if (DEBUG) {
        console.log('this.#_currentRoute set to', {
          path: this.#_pathStack.peek() as string,
          steps: currentRoute.steps,
        });
      }
    }

    const nextRoute = this.#_pathStack
      .toArray()
      .concat(path)
      .reduce((route: RouteTree, path: string) => {
        return route.nodes[path];
      }, this.#_tree as ApiTreeNode);

    if (DEBUG) {
      console.log('nextRoute:', nextRoute);
    }

    const previousHistorySteps = historyBeforeRebase
      .filter((entry) => entry.path === lastPath)
      .map(prop('step'));

    if (DEBUG) {
      console.log('previousHistorySteps:', previousHistorySteps);
    }

    const intersection = previousHistorySteps
      .filter((step) => {
        return (nextRoute || currentRoute).steps
          .find((nextRouteStep) => nextRouteStep.name === (step as ApiFormStep).name);
      });

    if (DEBUG) {
      console.log('intersection:', intersection);
    }

    const historyAfterRebase = [
      ...this.#_historyStack
        .toArray()
        .map(prop('step')),
      ...intersection,
    ];

    if (DEBUG) {
      console.log('historyAfterRebase:', historyAfterRebase);
      console.log(`Triggering 'after-rebase' hook`);
    }

    this.emit('after-rebase', {
      previousHistory: previousHistorySteps,
      rebasedHistory: historyAfterRebase,
    });

    if (DEBUG) {
      console.groupEnd();
    }
  }

  /**
   * Sets one or more state flags
   *
   * @param {RouterFlags} bitmask - A bitmask representing the router state to set
   */
  #_setFlag(bitmask: number): void {
    this.#_state |= bitmask;
  }

  /**
   * Clears one or more state flags
   *
   * @param {RouterFlags} bitmask - A bitmask representing the router state to clear
   */
  #_clearFlag(bitmask: number): void {
    this.#_state &= ~bitmask;
  }

  /**
   * Checks if one or more state flags are set
   *
   * @param {RouterFlags} bitmask - A bitmask representing the router state to check
   */
  #_isFlagSet(bitmask: number): boolean {
    return (this.#_state & bitmask) > 0;
  }

  #_debugFlags(label: string): void {
    const currentState = this.#_state.toString(2).padStart(6, '0');

    console.groupCollapsed(label);

    console.log('Current State:', currentState);

    // for (let i = 1; i <= currentState.length; i++) {
    //   const flag = parseInt(currentState[i].padStart(i, '0'), 10);

    //   this.#_isFlagSet(RouterFlags[`${RouterFlags['1']}`]);
    // }

    if (this.#_isFlagSet(RouterFlags.UNINITIALISED)) {
      console.log('UNINITIALISED is set');
    }

    if (this.#_isFlagSet(RouterFlags.INITIALISED)) {
      console.log('INITIALISED is set');
    }

    if (this.#_isFlagSet(RouterFlags.UPDATING)) {
      console.log('UPDATING is set');
    }

    if (this.#_isFlagSet(RouterFlags.ROUTE_START)) {
      console.log('ROUTE_START is set');
    }

    if (this.#_isFlagSet(RouterFlags.ROUTE_END)) {
      console.log('ROUTE_END is set');
    }

    if (this.#_isFlagSet(RouterFlags.ROUTE_PENDING)) {
      console.log('ROUTE_PENDING is set');
    }

    if (this.#_isFlagSet(RouterFlags.HISTORY_MODE)) {
      console.log('HISTORY_MODE is set');
    }

    console.groupEnd();
  }

  public get currentRoute() {
    if (typeof this.#_tree === 'undefined') {
      return null;
    }

    if (this.#_isFlagSet(RouterFlags.ROUTE_PENDING)) {
      return this.#_currentRoute;
    }

    const currentPath = this.#_pathStack.peek();

    if (!currentPath) {
      return null;
    }

    if (currentPath === this.#_currentRoute?.path) {
      return this.#_currentRoute;
    }

    const currentRoute = this.#_pathStack
      .toArray()
      .reduce((route: RouteTree, path: string) => {
        return route.nodes[path];
      }, this.#_tree as ApiTreeNode);

    if (!currentRoute) {
      return this.#_currentRoute;
    }

    this.#_currentRoute = {
      path: currentPath,
      steps: currentRoute.steps,
    };

    return this.#_currentRoute;
  }

  public get currentStep() {
    if (this.#_isFlagSet(RouterFlags.HISTORY_MODE)) {
      const historyEntry = this.#_historyStack.peek();

      return historyEntry?.step || null;
    }

    if (!this.currentRoute) {
      return null;
    }

    const currentStepIndex = this.#_trackedSteps.get(this.currentRoute.path) as number;

    if (currentStepIndex + 1 >= this.currentRoute.steps.length) {
      this.#_setFlag(RouterFlags.ROUTE_END);
    }

    return this.currentRoute.steps[currentStepIndex];
  }

  public get currentStepType() {
    const { currentStep } = this;

    if (!currentStep) {
      return null;
    }

    return currentStep.type;
  }

  public get isHistoryBackEnabled() {
    return !this.#_historyStack.isPointerAtBottom;
  }

  public get isHistoryForwardEnabled() {
    return !this.#_historyStack.isPointerAtTop;
  }

  public get isInitialised() {
    return this.#_isFlagSet(RouterFlags.INITIALISED);
  }
}

export default StepRouter;
