export const f = {
  tile: {
    width: 360,
    height: 150,
    crop: 'fill',
  },

  appImage: {
    width: 1400,
    height: 1400,
    crop: 'fill',
  },
};

const cleanFile = (file: any) => {
  if (file.includes('nausys')) {
    return `nausys/${file
      .split(/https?:\/\/ws\.nausys\.com\/CBMS-external\/rest\//)
      .pop()}`;
  }

  if (file.includes('booking-manager')) {
    return `bm/${file.split(/https?:\/\/www\.booking-manager\.com\/cbm\//).pop()}`;
  }

  if (file.includes('centralyachtagent')) {
    return `cya/${file
      .split(/https?:\/\/www\.centralyachtagent\.com\/yachtadmin\//)
      .pop()}`;
  }

  return `images/${file.split('/').pop()}`;
};

const stripFormat = (file: any) => file.split('.')[0];

export const video = (
  file: any,
  {
    width = false, height = false, crop = 'fill', blur = false,
  } = {},
) => {
  const h = height ? `h_${height},` : '';
  const w = width ? `w_${width},` : '';
  const f = stripFormat(cleanFile(file));
  const b = blur ? 'e_blur:750,' : '';
  const loc = file.split('/')[0] === 'a' ? '' : 'site/';
  return `https://res.cloudinary.com/helmyt/video/upload/${w}${h}c_${crop},${b}q_auto,vc_auto,ac_none/${loc}${f}.mp4`;
};

export default (
  file: any,
  { width = false, height = false, crop = 'scale' }: any,
  clean = false,
) => {
  if (!file) {
    console.error('Invalid filename supplied to image transform function.');

    return '';
  }

  const h = height ? `h_${height},` : '';
  const w = width ? `w_${width},` : '';
  const f = clean ? cleanFile(file) : file;
  const loc = file.split('/')[0] === 'a' ? '' : 'site/';
  return `https://res.cloudinary.com/helmyt/image/upload/${w}${h}c_${crop},q_auto,f_auto/e_trim/${loc}${f}`;
};
