import {
  isArray,
  isDef,
  isObject,
  isUndef,
} from '../utils/assertion';
import { prop } from '../utils/functional';

import { filterEachKey, forEachKey } from '../utils/iteration';

import { updateList } from '../utils/state';
import { toScreamingSnakeCase } from '../utils/string';

export interface FormStateActionPayload {
  name:  string;
  value: FormStateValue;
}

type SummaryDataValue = string | string[] | Record<string, string>;

export interface SummaryDataPayload extends FormStateActionPayload {
  value: SummaryDataValue;
}

export const enum FormStateActions {
  SET_VALUE           = 'SET_VALUE',
  SET_CONTACT_DETAILS = 'SET_CONTACT_DETAILS',
  SET_PIPEDRIVE_DATA  = 'SET_PIPEDRIVE_DATA',
  SET_SEARCH_PARAM    = 'SET_SEARCH_PARAM',
  SET_SEARCH_TERM     = 'SET_SEARCH_TERM',
  SET_SLACK_DATA      = 'SET_SLACK_DATA',
  SET_SUMMARY_MAP     = 'SET_SUMMARY_MAP',
  SET_SUMMARY_DATA    = 'SET_SUMMARY_DATA',
  UPDATE_LIST         = 'UPDATE_LIST',
  REBASE_STATE        = 'REBASE_STATE',
}

const SearchParamNames = {
  charterType: 'charterType',
  boatType: 'boatType',
  features: 'features',
  search: 'search',
} as const;

export type FormStateActionObject = ActionObject<FormStateActions, FormStateActionPayload>;

const updateSearchTerms = (value: SearchTerm, currentList: SearchTerm[]) => {
  const nonMatchingTaxonomyTerms = currentList
    .filter((item) => item.taxonomy !== value.taxonomy);

  if (typeof value.title === 'undefined') {
    return nonMatchingTaxonomyTerms;
  }

  if (nonMatchingTaxonomyTerms.length) {
    if (value.taxonomy === 'country') {
      return [value];
    }

    return [...nonMatchingTaxonomyTerms, value];
  }

  return [value];
};

interface PayloadConstraint {
  name: string,
  value: unknown,
}

const setObjectField = <
    ObjectType extends Record<string, unknown>,
    PayloadType extends PayloadConstraint
  >(
    state: ObjectType,
    fieldName: string,
    payload: PayloadType,
  ) => {
  const { [fieldName]: field } = state;
  const { [payload.name]: existingValue, ...rest } = field as any;

  // if (typeof payload.value === 'undefined') {
  //   const { [payload.name]: existingValue, ...rest } = field as any;

  //   return rest;
  // }

  const fieldValue = isDef(payload.value)
    ? {
      ...state[fieldName] as Record<string, unknown>,
      [payload.name]: payload.value,
    }
    : rest;

  return {
    ...state,
    [fieldName]: fieldValue,
  };
};

const setSlackData = (state: FormState, payload: FormStateActionPayload) => {
  if (isArray(payload.value)) {
    const { name, value: [value] } = payload as {
      name: string,
      value: FormStateValue[],
    };

    const { slackData } = state;
    const currentList = (slackData[name] || []) as FormStateValue[];

    return setObjectField(state, 'slackData', {
      name,
      value: updateList(value, currentList),
    });
  }

  if (isObject(payload.value)) {
    const { name, value: obj } = payload as any;
    const { name: label, value } = obj as any;

    return setObjectField(state, 'slackData', {
      name,
      value: {
        ...state.slackData[name] as any,
        [label]: isArray(value)
          ? updateList(value[0], ((state.slackData as any)?.[name]?.[label] || []) as FormStateValue[])
          : value,
      },
    });
  }

  // if (payload.name === 'country' && 'country' in state && 'location' in state) {
  //   const slackData = filterEachKey(state.slackData, (key) => {
  //     console.log(key.toLowerCase(), (state.country as string).toLowerCase(), !key.toLowerCase().includes((state.country as string).toLowerCase()));
  //     return !key.toLowerCase().includes((state.country as string).toLowerCase());
  //   });

  //   const stateWithoutLocation = { ...state, slackData };

  //   return setObjectField(stateWithoutLocation, 'slackData', payload);
  // }

  return setObjectField(state, 'slackData', payload);
};

/**
 * Not the best way to handle this, but will do for the time being.
 */
const deepRemoveValue = (state: Record<string, any>, values: unknown[]) => {
  let newState = state;

  values.forEach((value) => {
    forEachKey(state, (key) => {
      const fieldValue = state[key];

      if (fieldValue === null) return;

      if (typeof fieldValue === 'object') {
        if (!isArray(fieldValue)) {
          newState = { ...newState, [key]: deepRemoveValue(fieldValue, [value]) };
        }

        return;
      }

      if (fieldValue === value) {
        const { [key]: deletedKey, ...restState } = newState;

        newState = { ...restState };
      }
    });
  });

  return newState;
};

const formStateReducer = (state: FormState, action: FormStateActionObject): FormState => {
  const { type, payload } = action;

  switch (type) {
    case FormStateActions.SET_VALUE: {
      if (typeof payload.value === 'undefined') {
        const { [payload.name]: name, ...rest } = state;

        return rest as FormState;
      }

      // if (payload.name === 'country' && 'country' in state && 'location' in state) {
      //   const slackData = filterEachKey(state.slackData, (key) => {
      //     return !key.toLowerCase().includes((state.country as string).toLowerCase());
      //   });

      //   const stateWithoutSlackLocation = { ...state, slackData };

      //   return {
      //     ...stateWithoutSlackLocation,
      //     [payload.name]: payload.value,
      //   };
      // }

      return {
        ...state,
        [payload.name]: payload.value,
      };
    }

    case FormStateActions.SET_CONTACT_DETAILS: {
      return setObjectField(state, 'contactDetails', payload);
    }

    case FormStateActions.SET_PIPEDRIVE_DATA: {
      return setObjectField(state, 'pipedriveData', payload);
    }

    case FormStateActions.SET_SLACK_DATA: {
      return setSlackData(state, payload);
    }

    case FormStateActions.SET_SEARCH_PARAM: {
      const { name, value } = payload as {
        name: keyof typeof SearchParamNames,
        value: unknown,
      };

      if (isUndef(value)) return state;

      const searchData = state?.searchData as Record<string, unknown>;

      let formattedValue = null;

      switch (name) {
        case 'boatType': {
          formattedValue = toScreamingSnakeCase(value as string);

          break;
        }
        case 'features': {
          formattedValue = updateList(value as string, (searchData?.[name] || []) as string[]);

          break;
        }

        case 'search': {
          formattedValue = updateSearchTerms(value as SearchTerm, (searchData?.[name] || []) as SearchTerm[]);

          break;
        }

        default: {
          formattedValue = value;

          break;
        }
      }

      const formattedPayload = {
        name,
        value: formattedValue as FormStateValue,
      };

      return setObjectField(state, 'searchData', formattedPayload);
    }

    case FormStateActions.SET_SUMMARY_DATA: {
      if (typeof payload.value !== 'object') {
        return setObjectField(state, 'summaryData', payload);
      }

      interface SummaryConstraint extends SummaryDataPayload {
        value: Exclude<SummaryDataPayload['value'], string>
      }

      const { name, value } = payload as SummaryConstraint;

      const currentValue = state.summaryData?.[name] as SummaryConstraint['value'];

      if (isArray(value)) {
        if (value[0] === 'replace') {
          return setObjectField(state, 'summaryData', {
            name,
            value: value.slice(1),
          });
        }

        return setObjectField(state, 'summaryData', {
          name,
          value: updateList(value, (currentValue || []) as string[]),
        });
      }

      return setObjectField(state, 'summaryData', {
        name,
        value: {
          ...currentValue,
          ...value,
        },
      });
    }

    case FormStateActions.UPDATE_LIST: {
      const { name, value } = payload as { name: string, value: string };

      const currentList = (state?.[name] || []) as unknown[];

      return {
        ...state,
        [name]: updateList(value, currentList),
      };
    }

    case FormStateActions.REBASE_STATE: {
      const { previousHistory } = payload as any;

      const valuesToRemove = previousHistory
        .map((step: any) => {
          return state.slackData[step.legend];
        });

      const newState = deepRemoveValue(state, valuesToRemove);

      return {
        ...newState as FormState,
      };
    }

    default:
      throw new Error();
  }
};

export default formStateReducer;
