class EmitterEvent {
  public readonly id: string;

  public readonly payload: unknown;

  public constructor(name: string, payload: unknown) {
    this.id = name;
    this.payload = payload;
  }
}

export default EmitterEvent;
