import React, {
  useCallback,
  useRef,
} from 'react';

import transform, { f } from '../../utils/cloudinary-transforms';
import { conditionalArray } from '../../utils/misc';
import { Tick } from '../Icons';

import './form-input-option.css';

// ApiOption & OptionChangeHandler

const FormInputOption = (props: any) => {
  const {
    label,
    name,
    value,
    onChange,
    image = null,
    description,
    type,
    children,
    calloutTag,
    isChecked = false,
  } = props;

  const ref = useRef<HTMLInputElement>(null);

  const classList = conditionalArray(
    ['FormInputOption field', true],
    ['FormInputOption--basic', !(image || description)],
    ['FormInputOption', name === 'charter-type'],
  ).join(' ');

  const contentClassList = conditionalArray(
    ['FormInputOption__content', true],
    // ['FormInputOption__content--charter-type', name === 'charter-type'],
  ).join(' ');

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  }, [onChange]);

  return (
    <div className={ classList }>
      <div
        className="FormInputOption__input-wrapper"
      >
        <input
          ref={ ref }
          id={ `${type}-option-${value}` }
          type={ type }
          name={ name }
          value={ value }
          className="FormInputOption__input"
          onChange={ onChange }
          defaultChecked={ isChecked }
        />
        <label
          htmlFor={ `${type}-option-${value}` }
          className="FormInputOption__label text-h3 color-primary"
        >
          { label }
        </label>
      </div>
      {
        (image || description) && (
          <div className={ contentClassList }>
            {
              !!description && (
                <p className="FormInputOption__description small-text color-grey-darker">
                  { description }
                </p>
              )
            }
            {
              !!image && (
                <div className="FormInputOption__image">
                  <div className="FormInputOption__image-inner">
                    <img
                      src={ transform(image.src, f.tile, true) }
                      alt={ image.alt }
                    />
                  </div>
                  {
                    !!calloutTag && (
                      <span className="FormInputOption__callout-tag text-h3">
                        { calloutTag }
                      </span>
                    )
                  }
                </div>
              )
            }
          </div>
        )
      }
      { children }
      {
        (ref.current?.checked || isChecked) && (
          <span className="FormInputOption__tick">
            <Tick />
          </span>
        )
      }
    </div>
  );
};

export default FormInputOption;
