/* eslint-disable import/prefer-default-export */

export const toScreamingSnakeCase = (value: string): string => {
  return value.replace(/\s/g, '_').toUpperCase();
};

export const getPricingUnit = (price: number): 'M' | 'K' => {
  if (price / 1000 >= 1000) return 'M';

  return 'K';
};
