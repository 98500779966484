import React, {
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const Transition = (props: any) => {
  const {
    children,
    name,
    condition,
    mode,
    enterDelay,
    leaveDelay,
    onBeforeLeave,
    onAfterLeave,
    onBeforeEnter,
    onAfterEnter,
  } = props;

  const childRef = useRef(null);

  const [isMounted, setIsMounted] = useState(false);
  const [isEntering, setIsEntering] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);

  const clonedChildren = useMemo(() => React.cloneElement(children, {
    ref: childRef,
  }), [isMounted]);

  useLayoutEffect(() => {
    if (!isMounted) {
      if (!condition) return;

      setTimeout(() => {
        setIsMounted(true);
      }, enterDelay);

      return;
    }

    setTimeout(() => {
      if (condition) {
        if (mode === 'out-in') {
          setIsLeaving(true);
        }

        return;
      }

      setIsLeaving(true);
    }, leaveDelay);
  }, [condition]);

  useLayoutEffect(() => {
    if (!isMounted) {
      if (mode === 'out-in' && !!condition) {
        setIsMounted(true);
        setIsEntering(true);
      }

      return;
    }

    if (childRef.current) {
      const childEl = childRef.current as HTMLElement;

      if (typeof onBeforeEnter === 'function') {
        onBeforeEnter();
      }

      childEl.classList.add(`${name}-enter`, `${name}-enter-active`);

      requestAnimationFrame(() => {
        setIsEntering(true);
      });
    }
  }, [isMounted]);

  useLayoutEffect(() => {
    if (!isEntering) return;

    if (childRef.current) {
      const childEl = childRef.current as HTMLElement;

      requestAnimationFrame(() => {
        childEl.addEventListener('transitionend', () => {
          childEl.classList.remove(`${name}-enter-active`);

          if (typeof onAfterEnter === 'function') {
            onAfterEnter();
          }

          setIsEntering(false);
        }, { once: true });

        childEl.classList.remove(`${name}-enter`);
      });
    }
  }, [isEntering]);

  useLayoutEffect(() => {
    if (!isLeaving) return;

    if (childRef.current) {
      const childEl = childRef.current as HTMLElement;

      if (typeof onBeforeLeave === 'function') {
        onBeforeLeave();
      }

      childEl.classList.add(`${name}-leave-active`);

      requestAnimationFrame(() => {
        childEl.addEventListener('transitionend', () => {
          if (typeof onAfterLeave === 'function') {
            onAfterLeave();
          }

          setIsMounted(false);
          setIsLeaving(false);
        }, { once: true });

        childEl.classList.add(`${name}-leave-to`);
      });
    }
  }, [isLeaving]);

  if (!isMounted) return null;

  return clonedChildren;
};

export default Transition;
