/* eslint-disable max-len */
import React from 'react';

const LeftRightArrows = () => {
  return (
    <svg width="42" height="19" viewBox="0 0 42 19" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.19224 -4.47256e-06L-0.00015299 9.19238L0.110926 9.30346L-0.000157768 9.41454L9.19223 18.6069L10.6064 17.1927L2.71719 9.30347L10.6064 1.41421L9.19224 -4.47256e-06Z" fill="#C4C4C4"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M32.8083 18.6069L42.0006 9.41455L41.8896 9.30347L42.0006 9.19239L32.8083 1.68642e-08L31.394 1.41421L39.2833 9.30347L31.394 17.1927L32.8083 18.6069Z" fill="currentColor"/>
    </svg>
  );
};

export default LeftRightArrows;
