import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { AppStateContext } from '../../store/AppStateProvider';
import { FormStateContext } from '../../store/FormStateProvider';
import { getPricingUnit } from '../../utils/string';
import FormFieldSelect from '../FormFieldSelect';
import WidgetSlider from '../WidgetSlider';

import './widget-budget.css';
import { clamp } from '../../utils/misc';

// interface IncrementBreakpoint {
//   breakpoint: number;
//   value:      number;
// }

// interface Props {
//   additionalText: string;
//   stepAmount: number;
//   incrementBreakpoints: IncrementBreakpoint[];
//   onChange: CallableFunction;
// }

const WidgetBudget = (props: any) => {
  const {
    additionalText,
    stepAmount = 100,
    // incrementBreakpoints,
    onChange,
  } = props;

  const {
    state: appState,
    formatPrice,
    updateUserCurrency,
  } = useContext(AppStateContext);

  const {
    state: formState,
  } = useContext(FormStateContext);

  const {
    userCurrency = 'GBP',
    currencyCodes,
    availableBudgetRanges,
    budgetRange: {
      from,
      to,
    },
  } = appState;

  const [budgetValue, setBudgetValue] = useState(formState?.budget as number || 0);

  const budgetString = formatPrice(budgetValue || 0);

  const pricePerPerson = useMemo(() => {
    const { adults = 0 } = formState as Record<string, number>;

    if (adults < 2 || !budgetValue) return null;

    // if ((adults || 0) < 2) return formatPrice(budgetValue);

    return formatPrice(budgetValue / adults);
  }, [budgetValue, formatPrice, formState]);

  // console.log(pricePerPerson);

  const handleBudgetChange = useCallback((value: number) => {
    const summaryValue = [
      'replace',
      `${budgetString} Total`,
    ];

    if (pricePerPerson) {
      summaryValue.push(`${pricePerPerson} Per Person`);
    }

    onChange({
      label: 'Budget',
      name: 'budget',
      pipedriveField: 'budget',
      searchParam: 'price',
      value: clamp(value, from, to),
      slackValue: budgetString,
      summaryValue,
    });
  }, [budgetString, from, to, onChange, pricePerPerson]);

  const handleCurrencyChange = useCallback((payload: any) => {
    updateUserCurrency(payload.value);
    onChange(payload);
    // handleBudgetChange(formState?.budget as number);
  }, [onChange, updateUserCurrency]);

  useEffect(() => {
    if (!formState?.budget) return;

    handleBudgetChange(Number(formState?.budget));
  }, [appState.budgetRange]);

  useEffect(() => {
    if (!budgetValue) return;

    handleBudgetChange(budgetValue as number);
  }, [budgetValue]);

  useEffect(() => {
    handleCurrencyChange({
      name: 'budget-currency',
      label: 'Currency',
      value: userCurrency || 'GBP',
      pipedriveField: 'selectedCurrency',
    });
  }, []);

  const markerFormatter = useCallback((min: number, max: number) => {
    return [
      `<${formatPrice(min, { compact: true })}`,
      `${formatPrice(max, { compact: true })}+`,
    ];
  }, [formatPrice]);

  const availableCurrencies = useMemo(() => {
    return currencyCodes
      .map((key: string) => ({
        label: key,
        value: key,
      }));
  }, [currencyCodes]);

  return (
    <div className="WidgetBudget">
      <div className="WidgetBudget__budget-wrapper">
        <p className="WidgetBudget__budget text-h1 color-primary">
          { budgetString }
        </p>
        {
          !!pricePerPerson && (
            <p className="WidgetBudget__price-per-person text-h3 color-grey-darker">
              {  `${pricePerPerson} per person` }
            </p>
          )
        }
      </div>
      <div className="WidgetBudget__currency-select">
        <FormFieldSelect
          name="budget-currency"
          label="Currency"
          options={ availableCurrencies }
          pipedriveField="selectedCurrency"
          value={ formState['budget-currency'] || userCurrency }
          onChange={ handleCurrencyChange }
        />
        {/* <SelectInput selectParams={selectParams} /> */}
      </div>
      <div className="WidgetBudget__slider">
        <WidgetSlider
          value={ formState?.budget }
          min={ from }
          max={ to }
          step={ stepAmount }
          markerFormatter={ markerFormatter }
          onChange={ setBudgetValue }
        />
      </div>
      {
        !!additionalText && (
          <p className="WidgetBudget__small-print small-text color-grey-darker">
            { additionalText }
          </p>
        )
      }
    </div>
  );
};

export default WidgetBudget;
