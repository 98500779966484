import React, { useCallback, useContext, useEffect } from 'react';
import { AppStateContext } from '../../store/AppStateProvider';
import { FormStateContext } from '../../store/FormStateProvider';
import WidgetPhoneInputField from '../WidgetPhoneInput';

import './form-field-input.css';

interface FormFieldProps {
  id:                 string;
  label:              string;
  value?:             string | number;
  placeholder?:       string;
  required?:          boolean;
  type?:              'text' | 'number' | 'email';
  errorMessage?:      string;
  handleInputChange?: CallableFunction;
}

const FormFieldInput = (props: any) => {
  const {
    id,
    name,
    label,
    value,
    fieldType,
    placeholder,
    pipedriveField,
    required = false,
    errorMessage = '',
    onChange,
  } = props;

  const {
    state,
  } = useContext(AppStateContext);

  const handleChangeEvent = useCallback((
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange({
      name,
      label,
      pipedriveField,
      value: event.target.value || undefined,
    });
  }, [name, pipedriveField, onChange]);

  const sharedProps = {
    id,
    placeholder,
    required,
    onChange: handleChangeEvent,
  };

  return (
    <div className="FormFieldInput field">
      <label
        htmlFor={ id }
        className="FormFieldInput__label field__label"
      >
        { label }
        { required && <span>*</span> }
      </label>
      {
        errorMessage && (
          <div className="FormFieldInput__validation-message">
            { errorMessage }
          </div>
        )
      }
      {
        fieldType === 'textarea' && (
          <textarea
            rows={ 5 }
            className="field__textarea"
            { ...sharedProps }
          >
            { value }
          </textarea>
        )
      }

      {
        fieldType === 'tel' && (
          <WidgetPhoneInputField
            countryCode={ state.userCountryCode }
            { ...sharedProps }
          />
        )
      }

      {
        fieldType !== 'textarea' && fieldType !== 'tel' && (
          <input
            type={ fieldType }
            value={ value || '' }
            className="field__input"
            autoComplete="off"
            { ...sharedProps }
          />
        )
      }
    </div>
  );
};

export default FormFieldInput;
