import React, {
  createContext,
  ReactElement,
  useReducer,
} from 'react';

import formStateReducer, {
  FormStateActionPayload,
  FormStateActions,
  SummaryDataPayload,
} from './form-state-reducer';

import { defineAction } from '../utils/state';

const initialState: FormState = {
  pipedriveData:  {},
  searchData:     {},
  summaryData:    {},
  slackData:      {},
};

interface FormContext {
  state:            FormState;
  setValue:         React.Dispatch<FormStateActionPayload>;
  updateList:       React.Dispatch<FormStateActionPayload>;
  setPipedriveData: React.Dispatch<FormStateActionPayload>;
  setSearchParam:   React.Dispatch<FormStateActionPayload>;
  setSlackData:     React.Dispatch<FormStateActionPayload>;
  setSummaryData:   React.Dispatch<SummaryDataPayload>;
  rebaseFormState:  React.Dispatch<any>;
}

export const FormStateContext = createContext({} as FormContext);

const FormStateProvider = ({ children }: { children: ReactElement | ReactElement[] }) => {
  const [formState, dispatchFormState] = useReducer(formStateReducer, initialState);

  // console.log(formState);

  const providerContext: FormContext = {
    state:            formState,
    updateList:       defineAction(FormStateActions.UPDATE_LIST, dispatchFormState),
    setPipedriveData: defineAction(FormStateActions.SET_PIPEDRIVE_DATA, dispatchFormState),
    setSearchParam:   defineAction(FormStateActions.SET_SEARCH_PARAM, dispatchFormState),
    setValue:         defineAction(FormStateActions.SET_VALUE, dispatchFormState),
    setSlackData:     defineAction(FormStateActions.SET_SLACK_DATA, dispatchFormState),
    setSummaryData:   defineAction(FormStateActions.SET_SUMMARY_DATA, dispatchFormState),
    rebaseFormState:  defineAction(FormStateActions.REBASE_STATE, dispatchFormState),
  };

  return (
    <FormStateContext.Provider value={ providerContext }>
      { children }
    </FormStateContext.Provider>
  );
};

export default FormStateProvider;
