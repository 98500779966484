/* eslint-disable arrow-body-style */
import React, { useCallback } from 'react';

const useComponent = (components: Record<string, React.FunctionComponent>) => {
  return useCallback(({ is, ...props }: { is: string }): JSX.Element => {
    if (!(is in components)) {
      throw new Error(`No dynamic component declared with the name '${is}'`);
    }

    return React.createElement(components[is], props);
  }, [components]);
};

export default useComponent;
