/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */

import { isObject } from './assertion';

// import { isArray } from './assertion';

interface KeyIterator<Type, ReturnType = void> {
  (key: string, index: number, obj: Type): ReturnType;
}

export const forEachKey = <T>(obj: T, callback: KeyIterator<T>) => {
  let index = 0;

  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      callback(key, index, obj);

      index += 1;
    }
  }
};

export const filterEachKey = <T>(obj: T, callback: KeyIterator<T, boolean>) => {
  let index = 0;
  let newObj = {};

  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      if (callback(key, index, obj)) {
        newObj = {
          ...newObj,
          [key]: obj[key],
        };
      }

      index += 1;
    }
  }

  return newObj;
};

// export const filterEachKeyRecursive = <T extends Record<string, unknown>>(obj: T, callback: KeyIterator<T>): Record<string, any> => {
//   const newObj = {};

//   forEachKey(obj, (key) => {
//     if (isObject(obj[key])) {
//       const nestedObj = filterEachKeyRecursive(obj[key] as typeof obj, callback);

//       // return callback(key, index, {
//       //   ...obj,
//       //   [key]: nestedObj,
//       // });
//     }
//   })

//   const filteredObj = filterEachKey(obj, (key, index, obj) => {
//     if (isObject(obj[key])) {
//       const nestedObj = filterEachKeyRecursive(obj[key] as typeof obj, callback);

//       return callback(key, index, {
//         ...obj,
//         [key]: nestedObj,
//       });
//     }

//     return callback(key, index, obj);
//   });
// };

// export const recursiveFilter = (arr: , callback) => {
//   const newArr = [];

//   for (let i = 0; i < arr.length; i++) {
//     if (isArray(arr[i])) {
//       const nestedArr = recursiveFilter(arr[i], callback);

//       if (nestedArr.length) newArr.push(nestedArr);

//       continue;
//     }

//     if (!callback(arr[i])) continue;

//     newArr.push(arr[i]);
//   }

//   return newArr;
// };
