import EmitterEvent from './EmitterEvent';

class Emitter {
  #_listeners = new Map();

  public on(event: string, handler: EmitterEventHandler, options?: EmitterListenerOptions) {
    if (!this.#_listeners.has(event)) {
      this.#_listeners.set(event, []);
    }

    let callback: EmitterEventHandler;

    if (options?.once) {
      // Wraps the callback in a new function which will destroy
      // the listener once the callback has been called.
      callback = (e) => {
        handler(e);

        this.off(event, callback);
      };
    } else {
      callback = handler;
    }

    const listeners = this.#_listeners.get(event);

    if (options?.prepend) {
      listeners.unshift(callback);

      return;
    }

    listeners.push(callback);
  }

  public off(event: string, handler: EmitterEventHandler) {
    if (!this.#_listeners.has(event)) return;
    const listeners = this.#_listeners.get(event);

    if (listeners?.length < 2) {
      this.#_listeners.delete(event);

      return;
    }

    this.#_listeners.set(
      event,
      listeners
        .filter((callback: EmitterEventHandler) => callback !== handler),
    );
  }

  public emit(event: string, payload?: any) {
    const listeners = this.#_listeners.get(event);

    if (!listeners?.length) return;

    listeners
      .forEach((handler: EmitterEventHandler) => {
        try {
          handler(new EmitterEvent(event, payload));
        } catch (err) {
          console.error(err);
        }
      });
  }
}

export default Emitter;
