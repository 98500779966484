/* eslint-disable max-len */
import React from 'react';

const Tick = () => {
  return (
    <svg viewBox="0 0 15 12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.31603 10.5332L4.24264 11.6066L0 7.36393L1.41421 5.94971L4.31607 8.85157L13.1676 0L14.5819 1.41421L5.38946 10.6066L5.31603 10.5332Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Tick;
