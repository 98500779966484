import React from 'react';

const IconChevron = () => (
  <svg viewBox="0 0 13 8">
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.6 1.85 2.01.44l4.3 4.3L10.6.43l1.41 1.41-5.7 5.71L.6 1.86Z"
    />
  </svg>
);

export default IconChevron;
