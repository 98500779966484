import React, { useCallback, useContext } from 'react';
import { AppStateContext } from '../../store/AppStateProvider';
import { DownArrow } from '../Icons';

import './history-navigation.css';
import BaseButton from '../BaseButton';
// import router from '../../router/step-router';

const HistoryNavigation = () => {
  const {
    // appState,
    router,
    historyBack,
    historyForward,
    // setIsTransitioning,
  } = useContext(AppStateContext);

  // const navigateBack = useCallback(() => {
  //   setIsTransitioning(true);
  //   historyBack();
  // }, []);

  // const navigateForward = useCallback(() => {
  //   setIsTransitioning(true);
  //   historyForward();
  // }, []);

  return (
    <div className="HistoryNavigation">
      <BaseButton
        className="HistoryNavigation__button HistoryNavigation__button--back"
        onClick={ historyBack }
        disabled={ !router.isHistoryBackEnabled }
      >
      {/* <button
      > */}
        <DownArrow />
      </BaseButton>
      <BaseButton
        className="HistoryNavigation__button HistoryNavigation__button--forward"
        onClick={ historyForward }
        disabled={ !router.isHistoryForwardEnabled }
      >
        <DownArrow />
      </BaseButton>
    </div>
  );
};

export default HistoryNavigation;
