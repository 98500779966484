export const serviceTypeMap = new Map([
  ['bareboat', 'Bareboat'],
  ['skippered', 'Skipper'],
  ['crewed', 'Crewed charter'],
]);

export const boatTypeMap = new Map([
  ['sailing boat', 'Sailing Yacht'],
  ['sailing boats', 'Sailing Yacht'],
  ['sailing yacht', 'Sailing Yacht'],
  ['sailing yachts', 'Sailing Yacht'],
  ['catamaran', 'Catamaran'],
  ['catamarans', 'Catamaran'],
  ['motor boat', 'Motorboat'],
  ['motor boats', 'Motorboat'],
  ['motor yacht', 'Motorboat'],
  ['motor yachts', 'Motorboat'],
  ['gulet', 'Gulet'],
  ['gulets', 'Gulet'],
]);
