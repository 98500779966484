interface DialCodeData {
  dialCode: string,
  flag:     string,
}

const dialCodeData: Record<string, DialCodeData> = {
  AF: { dialCode: '+93', flag: '🇦🇫' },
  AL: { dialCode: '+355', flag: '🇦🇱' },
  DZ: { dialCode: '+213', flag: '🇩🇿' },
  AS: { dialCode: '+1684', flag: '🇦🇸' },
  AD: { dialCode: '+376', flag: '🇦🇩' },
  AO: { dialCode: '+244', flag: '🇦🇴' },
  AI: { dialCode: '+1264', flag: '🇦🇮' },
  AQ: { dialCode: '+672', flag: '🇦🇶' },
  AG: { dialCode: '+1268', flag: '🇦🇬' },
  AR: { dialCode: '+54', flag: '🇦🇷' },
  AM: { dialCode: '+374', flag: '🇦🇲' },
  AW: { dialCode: '+297', flag: '🇦🇼' },
  AU: { dialCode: '+61', flag: '🇦🇺' },
  AT: { dialCode: '+43', flag: '🇦🇹' },
  AZ: { dialCode: '+994', flag: '🇦🇿' },
  BS: { dialCode: '+1242', flag: '🇧🇸' },
  BH: { dialCode: '+973', flag: '🇧🇭' },
  BD: { dialCode: '+880', flag: '🇧🇩' },
  BB: { dialCode: '+1246', flag: '🇧🇧' },
  BY: { dialCode: '+375', flag: '🇧🇾' },
  BE: { dialCode: '+32', flag: '🇧🇪' },
  BZ: { dialCode: '+501', flag: '🇧🇿' },
  BJ: { dialCode: '+229', flag: '🇧🇯' },
  BM: { dialCode: '+1441', flag: '🇧🇲' },
  BT: { dialCode: '+975', flag: '🇧🇹' },
  BO: { dialCode: '+591', flag: '🇧🇴' },
  BA: { dialCode: '+387', flag: '🇧🇦' },
  BW: { dialCode: '+267', flag: '🇧🇼' },
  BR: { dialCode: '+55', flag: '🇧🇷' },
  IO: { dialCode: '+246', flag: '🇮🇴' },
  BN: { dialCode: '+673', flag: '🇧🇳' },
  BG: { dialCode: '+359', flag: '🇧🇬' },
  BF: { dialCode: '+226', flag: '🇧🇫' },
  BI: { dialCode: '+257', flag: '🇧🇮' },
  KH: { dialCode: '+855', flag: '🇰🇭' },
  CM: { dialCode: '+237', flag: '🇨🇲' },
  CA: { dialCode: '+1', flag: '🇨🇦' },
  CV: { dialCode: '+238', flag: '🇨🇻' },
  KY: { dialCode: '+345', flag: '🇰🇾' },
  CF: { dialCode: '+236', flag: '🇨🇫' },
  TD: { dialCode: '+235', flag: '🇹🇩' },
  CL: { dialCode: '+56', flag: '🇨🇱' },
  CN: { dialCode: '+86', flag: '🇨🇳' },
  CX: { dialCode: '+61', flag: '🇨🇽' },
  CC: { dialCode: '+61', flag: '🇨🇨' },
  CO: { dialCode: '+57', flag: '🇨🇴' },
  KM: { dialCode: '+269', flag: '🇰🇲' },
  CG: { dialCode: '+242', flag: '🇨🇬' },
  CD: { dialCode: '+243', flag: '🇨🇩' },
  CK: { dialCode: '+682', flag: '🇨🇰' },
  CR: { dialCode: '+506', flag: '🇨🇷' },
  CI: { dialCode: '+225', flag: '🇨🇮' },
  HR: { dialCode: '+385', flag: '🇭🇷' },
  CU: { dialCode: '+53', flag: '🇨🇺' },
  CY: { dialCode: '+357', flag: '🇨🇾' },
  CZ: { dialCode: '+420', flag: '🇨🇿' },
  DK: { dialCode: '+45', flag: '🇩🇰' },
  DJ: { dialCode: '+253', flag: '🇩🇯' },
  DM: { dialCode: '+1767', flag: '🇩🇲' },
  DO: { dialCode: '+1849', flag: '🇩🇴' },
  EC: { dialCode: '+593', flag: '🇪🇨' },
  EG: { dialCode: '+20', flag: '🇪🇬' },
  SV: { dialCode: '+503', flag: '🇸🇻' },
  GQ: { dialCode: '+240', flag: '🇬🇶' },
  ER: { dialCode: '+291', flag: '🇪🇷' },
  EE: { dialCode: '+372', flag: '🇪🇪' },
  ET: { dialCode: '+251', flag: '🇪🇹' },
  FK: { dialCode: '+500', flag: '🇫🇰' },
  FO: { dialCode: '+298', flag: '🇫🇴' },
  FJ: { dialCode: '+679', flag: '🇫🇯' },
  FI: { dialCode: '+358', flag: '🇫🇮' },
  FR: { dialCode: '+33', flag: '🇫🇷' },
  GF: { dialCode: '+594', flag: '🇬🇫' },
  PF: { dialCode: '+689', flag: '🇵🇫' },
  GA: { dialCode: '+241', flag: '🇬🇦' },
  GM: { dialCode: '+220', flag: '🇬🇲' },
  GE: { dialCode: '+995', flag: '🇬🇪' },
  DE: { dialCode: '+49', flag: '🇩🇪' },
  GH: { dialCode: '+233', flag: '🇬🇭' },
  GI: { dialCode: '+350', flag: '🇬🇮' },
  GR: { dialCode: '+30', flag: '🇬🇷' },
  GL: { dialCode: '+299', flag: '🇬🇱' },
  GD: { dialCode: '+1473', flag: '🇬🇩' },
  GP: { dialCode: '+590', flag: '🇬🇵' },
  GU: { dialCode: '+1671', flag: '🇬🇺' },
  GT: { dialCode: '+502', flag: '🇬🇹' },
  GG: { dialCode: '+44', flag: '🇬🇬' },
  GN: { dialCode: '+224', flag: '🇬🇳' },
  GW: { dialCode: '+245', flag: '🇬🇼' },
  GY: { dialCode: '+592', flag: '🇬🇾' },
  HT: { dialCode: '+509', flag: '🇭🇹' },
  VA: { dialCode: '+379', flag: '🇻🇦' },
  HN: { dialCode: '+504', flag: '🇭🇳' },
  HK: { dialCode: '+852', flag: '🇭🇰' },
  HU: { dialCode: '+36', flag: '🇭🇺' },
  IS: { dialCode: '+354', flag: '🇮🇸' },
  IN: { dialCode: '+91', flag: '🇮🇳' },
  ID: { dialCode: '+62', flag: '🇮🇩' },
  IR: { dialCode: '+98', flag: '🇮🇷' },
  IQ: { dialCode: '+964', flag: '🇮🇶' },
  IE: { dialCode: '+353', flag: '🇮🇪' },
  IM: { dialCode: '+44', flag: '🇮🇲' },
  IL: { dialCode: '+972', flag: '🇮🇱' },
  IT: { dialCode: '+39', flag: '🇮🇹' },
  JM: { dialCode: '+1876', flag: '🇯🇲' },
  JP: { dialCode: '+81', flag: '🇯🇵' },
  JE: { dialCode: '+44', flag: '🇯🇪' },
  JO: { dialCode: '+962', flag: '🇯🇴' },
  KZ: { dialCode: '+77', flag: '🇰🇿' },
  KE: { dialCode: '+254', flag: '🇰🇪' },
  KI: { dialCode: '+686', flag: '🇰🇮' },
  KP: { dialCode: '+850', flag: '🇰🇵' },
  KR: { dialCode: '+82', flag: '🇰🇷' },
  KW: { dialCode: '+965', flag: '🇰🇼' },
  KG: { dialCode: '+996', flag: '🇰🇬' },
  LA: { dialCode: '+856', flag: '🇱🇦' },
  LV: { dialCode: '+371', flag: '🇱🇻' },
  LB: { dialCode: '+961', flag: '🇱🇧' },
  LS: { dialCode: '+266', flag: '🇱🇸' },
  LR: { dialCode: '+231', flag: '🇱🇷' },
  LY: { dialCode: '+218', flag: '🇱🇾' },
  LI: { dialCode: '+423', flag: '🇱🇮' },
  LT: { dialCode: '+370', flag: '🇱🇹' },
  LU: { dialCode: '+352', flag: '🇱🇺' },
  MO: { dialCode: '+853', flag: '🇲🇴' },
  MK: { dialCode: '+389', flag: '🇲🇰' },
  MG: { dialCode: '+261', flag: '🇲🇬' },
  MW: { dialCode: '+265', flag: '🇲🇼' },
  MY: { dialCode: '+60', flag: '🇲🇾' },
  MV: { dialCode: '+960', flag: '🇲🇻' },
  ML: { dialCode: '+223', flag: '🇲🇱' },
  MT: { dialCode: '+356', flag: '🇲🇹' },
  MH: { dialCode: '+692', flag: '🇲🇭' },
  MQ: { dialCode: '+596', flag: '🇲🇶' },
  MR: { dialCode: '+222', flag: '🇲🇷' },
  MU: { dialCode: '+230', flag: '🇲🇺' },
  YT: { dialCode: '+262', flag: '🇾🇹' },
  MX: { dialCode: '+52', flag: '🇲🇽' },
  FM: { dialCode: '+691', flag: '🇫🇲' },
  MD: { dialCode: '+373', flag: '🇲🇩' },
  MC: { dialCode: '+377', flag: '🇲🇨' },
  MN: { dialCode: '+976', flag: '🇲🇳' },
  ME: { dialCode: '+382', flag: '🇲🇪' },
  MS: { dialCode: '+1664', flag: '🇲🇸' },
  MA: { dialCode: '+212', flag: '🇲🇦' },
  MZ: { dialCode: '+258', flag: '🇲🇿' },
  MM: { dialCode: '+95', flag: '🇲🇲' },
  NA: { dialCode: '+264', flag: '🇳🇦' },
  NR: { dialCode: '+674', flag: '🇳🇷' },
  NP: { dialCode: '+977', flag: '🇳🇵' },
  NL: { dialCode: '+31', flag: '🇳🇱' },
  AN: { dialCode: '+599', flag: '🇳🇦' },
  NC: { dialCode: '+687', flag: '🇳🇨' },
  NZ: { dialCode: '+64', flag: '🇳🇿' },
  NI: { dialCode: '+505', flag: '🇳🇮' },
  NE: { dialCode: '+227', flag: '🇳🇪' },
  NG: { dialCode: '+234', flag: '🇳🇬' },
  NU: { dialCode: '+683', flag: '🇳🇺' },
  NF: { dialCode: '+672', flag: '🇳🇫' },
  MP: { dialCode: '+1670', flag: '🇲🇵' },
  NO: { dialCode: '+47', flag: '🇳🇴' },
  OM: { dialCode: '+968', flag: '🇴🇲' },
  PK: { dialCode: '+92', flag: '🇵🇰' },
  PW: { dialCode: '+680', flag: '🇵🇼' },
  PS: { dialCode: '+970', flag: '🇵🇸' },
  PA: { dialCode: '+507', flag: '🇵🇦' },
  PG: { dialCode: '+675', flag: '🇵🇬' },
  PY: { dialCode: '+595', flag: '🇵🇾' },
  PE: { dialCode: '+51', flag: '🇵🇪' },
  PH: { dialCode: '+63', flag: '🇵🇭' },
  PN: { dialCode: '+872', flag: '🇵🇳' },
  PL: { dialCode: '+48', flag: '🇵🇱' },
  PT: { dialCode: '+351', flag: '🇵🇹' },
  PR: { dialCode: '+1939', flag: '🇵🇷' },
  QA: { dialCode: '+974', flag: '🇶🇦' },
  RO: { dialCode: '+40', flag: '🇷🇴' },
  RU: { dialCode: '+7', flag: '🇷🇺' },
  RW: { dialCode: '+250', flag: '🇷🇼' },
  RE: { dialCode: '+262', flag: '🇷🇪' },
  BL: { dialCode: '+590', flag: '🇧🇱' },
  SH: { dialCode: '+290', flag: '🇸🇭' },
  KN: { dialCode: '+1869', flag: '🇰🇳' },
  LC: { dialCode: '+1758', flag: '🇱🇨' },
  MF: { dialCode: '+590', flag: '🇲🇫' },
  PM: { dialCode: '+508', flag: '🇵🇲' },
  VC: { dialCode: '+1784', flag: '🇻🇨' },
  WS: { dialCode: '+685', flag: '🇼🇸' },
  SM: { dialCode: '+378', flag: '🇸🇲' },
  ST: { dialCode: '+239', flag: '🇸🇹' },
  SA: { dialCode: '+966', flag: '🇸🇦' },
  SN: { dialCode: '+221', flag: '🇸🇳' },
  RS: { dialCode: '+381', flag: '🇷🇸' },
  SC: { dialCode: '+248', flag: '🇸🇨' },
  SL: { dialCode: '+232', flag: '🇸🇱' },
  SG: { dialCode: '+65', flag: '🇸🇬' },
  SK: { dialCode: '+421', flag: '🇸🇰' },
  SI: { dialCode: '+386', flag: '🇸🇮' },
  SB: { dialCode: '+677', flag: '🇸🇧' },
  SO: { dialCode: '+252', flag: '🇸🇴' },
  ZA: { dialCode: '+27', flag: '🇿🇦' },
  GS: { dialCode: '+500', flag: '🇬🇸' },
  ES: { dialCode: '+34', flag: '🇪🇸' },
  LK: { dialCode: '+94', flag: '🇱🇰' },
  SD: { dialCode: '+249', flag: '🇸🇩' },
  SS: { dialCode: '+211', flag: '🇸🇸' },
  SR: { dialCode: '+597', flag: '🇸🇷' },
  SJ: { dialCode: '+47', flag: '🇸🇯' },
  SZ: { dialCode: '+268', flag: '🇸🇿' },
  SE: { dialCode: '+46', flag: '🇸🇪' },
  CH: { dialCode: '+41', flag: '🇨🇭' },
  SY: { dialCode: '+963', flag: '🇸🇾' },
  TW: { dialCode: '+886', flag: '🇹🇼' },
  TJ: { dialCode: '+992', flag: '🇹🇯' },
  TZ: { dialCode: '+255', flag: '🇹🇿' },
  TH: { dialCode: '+66', flag: '🇹🇭' },
  TL: { dialCode: '+670', flag: '🇹🇱' },
  TG: { dialCode: '+228', flag: '🇹🇬' },
  TK: { dialCode: '+690', flag: '🇹🇰' },
  TO: { dialCode: '+676', flag: '🇹🇴' },
  TT: { dialCode: '+1868', flag: '🇹🇹' },
  TN: { dialCode: '+216', flag: '🇹🇳' },
  TR: { dialCode: '+90', flag: '🇹🇷' },
  TM: { dialCode: '+993', flag: '🇹🇲' },
  TC: { dialCode: '+1649', flag: '🇹🇨' },
  TV: { dialCode: '+688', flag: '🇹🇻' },
  UG: { dialCode: '+256', flag: '🇺🇬' },
  UA: { dialCode: '+380', flag: '🇺🇦' },
  AE: { dialCode: '+971', flag: '🇦🇪' },
  GB: { dialCode: '+44', flag: '🇬🇧' },
  US: { dialCode: '+1', flag: '🇺🇸' },
  UY: { dialCode: '+598', flag: '🇺🇾' },
  UZ: { dialCode: '+998', flag: '🇺🇿' },
  VU: { dialCode: '+678', flag: '🇻🇺' },
  VE: { dialCode: '+58', flag: '🇻🇪' },
  VN: { dialCode: '+84', flag: '🇻🇳' },
  VG: { dialCode: '+1284', flag: '🇻🇬' },
  VI: { dialCode: '+1340', flag: '🇻🇮' },
  WF: { dialCode: '+681', flag: '🇼🇫' },
  YE: { dialCode: '+967', flag: '🇾🇪' },
  ZM: { dialCode: '+260', flag: '🇿🇲' },
  ZW: { dialCode: '+263', flag: '🇿🇼' },
  AX: { dialCode: '+358', flag: '🇦🇽' },
};

export default dialCodeData;
