import React, { FocusEventHandler, KeyboardEventHandler, MouseEventHandler } from 'react';
import { conditionalArray } from '../../utils/misc';

import './widget-combo-box-input.css';
import { IconChevron } from '../Icons';

interface ComboBoxInputProps {
  ariaExpanded?: boolean;
  label:         string;
  popupId:       string;
  value?:        string;
  onClick?:      MouseEventHandler<HTMLDivElement>;
  onFocus?:      FocusEventHandler<HTMLDivElement>;
  onKeyDown?:    KeyboardEventHandler<HTMLDivElement>;
}

const WidgetComboBoxInput = (props: ComboBoxInputProps) => {
  const {
    label,
    popupId,
    ariaExpanded = false,
    value = '',
    onClick,
    onFocus,
    onKeyDown,
  } = props;

  return (
    <div className="WidgetComboBoxInput field">
      <label
        htmlFor={ popupId }
        className="field__label"
      >
        { label }
      </label>
      <div className="WidgetComboBoxInput__input-wrapper">
        <div
          role="combobox"
          className="WidgetComboBoxInput__input field__input"
          aria-autocomplete="none"
          aria-controls={ popupId }
          aria-expanded={ ariaExpanded }
          // aria-activedescendant={ activeDescendant }
          aria-live="polite"
          aria-relevant='additions removals'
          onFocus={ onFocus }
          onKeyDown={ onKeyDown }
          onClick={ onClick }
          tabIndex={ 0 }
        >
          { value }
        </div>
        <span className="WidgetComboBoxInput__icon">
          <IconChevron />
        </span>
      </div>
    </div>
  );
};

export default WidgetComboBoxInput;
