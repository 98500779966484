import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { DownArrow } from '../Icons';
import { FormStateContext } from '../../store/FormStateProvider';
import FormSummaryItem from './FormSummaryItem';

import './form-summary.css';

const FormSummary = () => {
  const {
    state: formState,
  } = useContext(FormStateContext);

  const detailsRef = useRef<HTMLDivElement>(null);

  const { summaryData } = formState;

  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleToggleKeypress = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    switch (event.code) {
      case 'Enter':
      case 'Space':
        toggleIsOpen();

        break;

      default:
        break;
    }
  }, [toggleIsOpen]);

  useEffect(() => {
    if (!detailsRef.current) {
      return;
    }

    detailsRef.current.classList
      .toggle('FormSummary__details--is-open');
  }, [isOpen, detailsRef]);

  return (
    <div className="FormSummary">
      <div className="FormSummary__inner">
        <div
          role="button"
          tabIndex={ 0 }
          className="FormSummary__header"
          onClick={ toggleIsOpen }
          onKeyDown={ handleToggleKeypress }
        >
          <p className="text-h1">
            Your Trip Summary
          </p>
          <span className="FormSummary__header-icon">
            <DownArrow />
          </span>
        </div>
        <div
          ref={ detailsRef }
          className="FormSummary__details"
        >
          <dl className="FormSummary__details-list text-h3">
            {
              Object.keys(summaryData)
                .map((key) => (
                  <FormSummaryItem
                    key={ key }
                    label={ key }
                    value={ summaryData[key] }
                  />
                ))
            }
          </dl>
        </div>
      </div>
    </div>
  );
};

export default FormSummary;
