class StackSet<T> implements Stack<T>, Arrayable<T> {
  #entries: Set<T>;

  public constructor(iterable?: Iterable<T> | null) {
    this.#entries = new Set<T>(iterable);
  }

  public push(value: T) {
    this.#entries.add(value);
  }

  public pop(): T | undefined {
    const value = this.peek();

    if (value !== null && typeof value) {
      this.#entries.delete(value as T);
    }

    return value;
  }

  public peek(): T | undefined {
    return this.toArray().at(-1);
  }

  public toArray(): T[] {
    return [...this.#entries.values()];
  }

  public get size(): number {
    return this.#entries.size;
  }

  // eslint-disable-next-line class-methods-use-this
  public get [Symbol.toStringTag]() {
    return 'StackSet';
  }
}

export default StackSet;
