import React, { ChangeEvent, useCallback, useState } from 'react';
import dialCodeData from '../../data-sources/country-data';
import { IconChevron } from '../Icons';

import './widget-phone-input.css';

const WidgetPhoneInputField = (props: any) => {
  const {
    id,
    placeholder,
    required,
    value,
    countryCode = 'GB',
    onChange,
  } = props;

  // Defaults to UK flag and dialling code
  const {
    dialCode = '+44',
    flag = '\uD83C\uDDEC\uD83C\uDDE7', // Unicode value for UK flag
  } = dialCodeData?.[countryCode] || {};

  const [selectedDialCode, setSelectedDialCode] = useState(dialCode);

  const handleNumberInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...event,
      target: {
        ...event.target,
        value: `${selectedDialCode} ${event.target.value}`,
      },
    });
  }, [selectedDialCode, onChange]);

  const handleDialCodeChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedDialCode(event.target.value.slice(flag.length + 1));
  }, [flag]);

  return (
    <span className="WidgetPhoneInput">
      <span className="WidgetPhoneInput__dial-code">
        <select
          className="WidgetPhoneInput__dial-code-input"
          onChange={ handleDialCodeChange }
          defaultValue={ `${flag} ${dialCode}` }
        >
          {
            Object.values(dialCodeData)
              .map((value, index) => (
                <option
                  key={ `${value.dialCode}-${index}` }
                  value={ `${value.flag} ${value.dialCode}` }
                >
                  { value.flag } { value.dialCode }
                </option>
              ))
          }
        </select>
        <span className="WidgetPhoneInput__select-icon">
          <IconChevron />
        </span>
      </span>
      <input
        id={ id }
        type="tel"
        value={ value }
        placeholder={ placeholder }
        autoComplete="off"
        required={ required }
        className="WidgetPhoneInput__number-input"
        onChange={ handleNumberInputChange }
      />
    </span>
  );
};

export default WidgetPhoneInputField;
