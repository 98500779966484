export const isString = (val: unknown) => typeof val === 'string';

export const isNumber = (val: unknown) => typeof val === 'number';

export const isFunc = (val: unknown) => typeof val === 'function';

export const isBool = (val: unknown) => typeof val === 'boolean';

export const isTrue = (val: unknown) => val === true;

export const isFalse = (val: unknown) => val === false;

export const isUndef = (val: unknown) => val === undefined || val === null;

export const isDef = (val: unknown) => val !== undefined && val !== null;

export const { isArray } = Array;

export const isObject = (val: unknown) =>
  isDef(val) && typeof val === 'object' && !isArray(val);

export const isZero = (val: unknown) => isNumber(val) && val === 0;

export const isEmptyString = (val: unknown) => isString(val) && !val;

export const isEmptyObject = (val: unknown) =>
  isObject(val) && !Object.keys(val as object).length;

export const isEmptyArray = (val: unknown) => isArray(val) && isZero(val.length);

export const isEmpty = (val: unknown) =>
  isUndef(val)
    || isEmptyObject(val as object)
    || isEmptyArray(val)
    || isEmptyString(val)
    || (isNumber(val) && Number.isNaN(val));

export const assert = (condition: boolean, message: string) => {
  if (!condition) throw new Error(message);
};

export const assertType = (condition: boolean, type: string) => {
  if (typeof condition !== type) {
    throw new Error(`Expected type ${type}, but got ${typeof condition}`);
  }
};

export const isDate = (val: unknown) => val instanceof Date;

export const isRegex = (val: unknown) => val instanceof RegExp;
