export const enum AppStateActions {
  INIT_DATA               = 'INIT_DATA',
  NEXT_STEP               = 'NEXT_STEP',
  ROUTE_ENTER             = 'ROUTE_ENTER',
  ROUTE_LEAVE             = 'ROUTE_LEAVE',
  SET_PENDING_ROUTE       = 'SET_PENDING_ROUTE',
  PUSH_ROUTE              = 'PUSH_ROUTE',
  SET_REQUIRED_FIELD      = 'SET_REQUIRED_FIELD',
  UPDATE_REQUIRED_FIELDS  = 'UPDATE_REQUIRED_FIELDS',
  SET_COUNTRY_CODE        = 'SET_COUNTRY_CODE',
  SET_CURRENCY            = 'SET_CURRENCY',
  SET_IS_TRANSITIONING    = 'SET_IS_TRANSITIONING',
  INIT_USER_DATA          = 'INIT_USER_DATA',
  UPDATE_USER_CURRENCY    = 'UPDATE_USER_CURRENCY',
  INIT_BUDGET_RANGES      = 'INIT_BUDGET_RANGES',
  UPDATE_BUDGET_RANGE     = 'UPDATE_BUDGET_RANGE',
  HISTORY_BACK            = 'HISTORY_BACK',
  HISTORY_FORWARD         = 'HISTORY_FORWARD',
}

export type AppStateActionObject = ActionObject<AppStateActions, any>;

interface UserData {
  userCurrency:    string;
  userCountryCode: string;
}

interface UserDataPayload extends UserData {
  countryCodes: string[];
}

const appStateReducer = (state: AppState, action: AppStateActionObject): AppState => {
  switch (action.type) {
    case AppStateActions.INIT_DATA: {
      const formData = action.payload as ApiResponse;

      if (!state.router.isInitialised) {
        state.router.init(formData.tree);
      }

      return {
        ...state,
        availabilityRules: formData.availabilityRules?.rules || [],
        exchangeRates: formData.exchangeRates,
      };
    }

    case AppStateActions.INIT_USER_DATA: {
      return {
        ...state,
        ...action.payload as UserDataPayload,
      };
    }

    case AppStateActions.UPDATE_USER_CURRENCY: {
      const budgetRange = state.availableBudgetRanges[action.payload]
        || state.budgetRange;

      return {
        ...state,
        userCurrency: action.payload as string,
        budgetRange,
      };
    }

    case AppStateActions.NEXT_STEP: {
      if (!state.router.isInitialised) {
        return state;
      }

      state.router.next();

      return { ...state };
    }

    case AppStateActions.SET_PENDING_ROUTE: {
      return {
        ...state,
        pendingRoutePath: action.payload,
      };
    }

    case AppStateActions.SET_IS_TRANSITIONING: {
      return {
        ...state,
        isTransitioning: action.payload,
      };
    }

    case AppStateActions.PUSH_ROUTE: {
      if (!state.router.isInitialised || state.isTransitioning) {
        return state;
      }

      state.router.push(action.payload as string);

      return state;
    }

    case AppStateActions.UPDATE_REQUIRED_FIELDS: {
      const requiredFields = !Array.isArray(action.payload)
        ? [...new Set([...state.requiredFields, action.payload as string])]
        : action.payload;

      // console.log(action.payload, requiredFields);

      return {
        ...state,
        requiredFields,
      };
    }

    case AppStateActions.INIT_BUDGET_RANGES: {
      const budgetRange = action.payload[state.userCurrency]
        || state.budgetRange;

      return {
        ...state,
        availableBudgetRanges: action.payload,
        budgetRange,
      };
    }

    case AppStateActions.UPDATE_BUDGET_RANGE: {
      return {
        ...state,
        budgetRange: state.availableBudgetRanges[action.payload],
      };
    }

    case AppStateActions.HISTORY_BACK: {
      if (!state.router.isInitialised || state.isTransitioning) {
        return state;
      }

      state.router.back();

      return {
        ...state,
        isTransitioning: true,
        requiredFields: [],
      };
    }

    case AppStateActions.HISTORY_FORWARD: {
      if (!state.router.isInitialised || state.isTransitioning) {
        return state;
      }

      state.router.forward();

      return {
        ...state,
        isTransitioning: true,
        requiredFields: [],
      };
    }

    default:
      throw new Error();
  }
};

export default appStateReducer;
