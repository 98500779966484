import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import jump from 'jump.js';

import { AppStateContext } from '../../store/AppStateProvider';
import { FormStateContext } from '../../store/FormStateProvider';
import { StepTypes } from '../../types';
import { isEmpty } from '../../utils/assertion';
import BaseButton from '../BaseButton';
import FormStep from '../FormStep';
import Transition from '../Transition';

import './form-handler.css';
import { noop } from '../../utils/misc';
import HistoryNavigation from '../HistoryNavigation';

const scrollToTop = () => {
  jump(document.documentElement);
};

const FormHandler = (props: any) => {
  const { onAfterLeave } = props;

  const {
    state: formState,
    rebaseFormState,
  } = useContext(FormStateContext);

  const {
    state: appState,
    router,
    currentStep,
    nextStep,
    setPendingRoute,
    updateRequiredFields,
    setIsTransitioning,
  } = useContext(AppStateContext);

  const { pendingRoutePath, requiredFields } = appState;

  const formRef = useRef<HTMLFormElement>(null);

  const [buttonText, setButtonText] = useState('Next');

  const handleOnAfterEnter = useCallback(() => {
    setIsTransitioning(false);
  }, [setIsTransitioning]);

  // console.log(requiredFields);

  const handleOnAfterLeave = useCallback(() => {
    onAfterLeave();

    setButtonText(
      currentStep?.type === StepTypes.CONTACT
        ? 'Submit'
        : 'Next',
    );

    if (currentStep?.type !== StepTypes.THANK_YOU) {
      return;
    }

    window.parent.postMessage({
      source: 'enquiry-builder',
      type: 'form-submitted',
      payload: {
        pageData: currentStep,
        formData: formState,
      },
    }, '*');
  }, [currentStep, formState, onAfterLeave]);

  const buttonAction = useCallback(() => {
    if (appState.isTransitioning || !formRef.current) return;

    const requiredFormFields = formRef.current
      .querySelectorAll<HTMLFormElement>('[required]');

    for (const field of requiredFormFields) {
      if (!field.reportValidity()) return;
    }

    setIsTransitioning(true);
    nextStep();
    scrollToTop();
  }, [appState.isTransitioning, nextStep, setIsTransitioning]);

  useEffect(() => {
    const rebaseStateCallback = (event: EmitterEvent) => {
      const history = event.payload;

      queueMicrotask(() => {
        rebaseFormState(history);
      });
    };

    const unsetPendingRouteChange = () => {
      queueMicrotask(() => {
        setPendingRoute(null);
      });
    };

    router.on('after-rebase', rebaseStateCallback);
    router.on('history-forward', unsetPendingRouteChange);

    return () => {
      router.off('after-rebase', rebaseStateCallback);
      router.off('history-forward', unsetPendingRouteChange);
    };
  }, []);

  useEffect(() => {
    if (!requiredFields.length) {
      return;
    }

    const incompleteFields = requiredFields
      .filter((name) => isEmpty(formState?.[name]));

    updateRequiredFields(incompleteFields);
  }, [currentStep, formState]);

  useEffect(() => {
    if (!pendingRoutePath) {
      return noop;
    }

    const pushPendingRouteChange = () => {
      router.push(pendingRoutePath);

      queueMicrotask(() => {
        setPendingRoute(null);
      });
    };

    router.on('next-step', pushPendingRouteChange);

    return () => {
      router.off('next-step', pushPendingRouteChange);
    };
  }, [pendingRoutePath]);

  return currentStep
    ? (
      <form
        className="FormHandler"
        ref={ formRef }
      >
        <Transition
          name="fade-up"
          mode="out-in"
          condition={ currentStep }
          onAfterEnter={ handleOnAfterEnter }
          onAfterLeave={ handleOnAfterLeave }
        >
          <div className="FormHandler__content grid">
            <FormStep { ...currentStep } />
          </div>
        </Transition>
        <div className="FormHandler__footer">
          <HistoryNavigation />
          <BaseButton
            onClick={ buttonAction }
            disabled={ appState.isTransitioning || !!requiredFields.length }
          >
            { buttonText }
          </BaseButton>
        </div>
      </form>
    )
    : null;
};

export default FormHandler;
