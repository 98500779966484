/* eslint-disable react/display-name */
import React from 'react';

import './form-fieldset.css';
import { isUndef } from '../../utils/assertion';

const FormFieldset = (WrappedComponent: any) => (props: any) => (
  isUndef(props.legend)
    ? <WrappedComponent { ...props } />
    : (
        <fieldset className="FormFieldset">
          <div>
            <legend className="text-h3">
              { props.legend?.replace(/\\n/g, '\n') }
            </legend>
          </div>
          <WrappedComponent { ...props } />
        </fieldset>
    )
);

export default FormFieldset;
