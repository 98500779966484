import React, { useMemo } from 'react';
import { isArray } from '../../utils/assertion';

const FormSummaryItem = ({ label, value }: any) => {
  const valueMarkup = useMemo(() => {
    if (typeof value !== 'object') {
      return value;
    }

    const list = isArray(value) ? value : Object.values(value);

    return (
      <ul>
        {
          list.map((item) => (
            <li key={ item }>
              { item }
            </li>
          ))
        }
      </ul>
    );
  }, [value]);

  return (
    <>
      <dt className="FormSummary__details-item-label">
        { label }
      </dt>
      <dd>
        { valueMarkup }
      </dd>
    </>
  );
};

export default FormSummaryItem;
