/* eslint-disable max-len */
import React from 'react';

const DownArrow = () => {
  return (
    <svg viewBox="0 0 19 12" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="m9.41406 11.3032 9.19234-9.19236L17.1922.696627 9.30322 8.58564 1.41421.696621-.00000492 2.11083 9.19238 11.3032l.11084-.1108.11084.1108Z" fill="currentColor"/>
    </svg>
  );
};

export default DownArrow;
