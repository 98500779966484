export const conditionalArray = (...entries: any) => (
  entries.reduce((acc: any, [item, condition]: [any, any]) => {
    if (condition && item) {
      // eslint-disable-next-line no-param-reassign
      acc = [...acc, item];
    }

    return acc;
  }, [])
);

export const clamp = (num: number, min: number, max: number): number => (
  Math.min(Math.max(num, min), max)
);

export const noop = (...args: any[]): void => { /* */ };

export const toBinary = (value: number, width = 8) => {
  return value
    .toString(2)
    .padStart(width, '0');
};
