interface ListNode<T> {
  value: T;
  next:  ListNode<T> | null;
}

const createNode = <T>(
  value: T,
  next: ListNode<T> | null = null,
): ListNode<T> => {
  const node = Object.create(null);

  node.value = value;
  node.next = next;

  return node;
};

class LinkedList<T> implements Iterable<ListNode<T>>, Arrayable<T> {
  #size = 0;

  #head: ListNode<T> | null = null;

  #tail: ListNode<T> | null = null;

  public insert(value: T) {
    const node = createNode(value);

    this.#size += 1;

    if (!this.#head) {
      this.#head = node;
      this.#tail = node;

      return node;
    }

    if (!this.#tail) {
      throw new Error(
        'The tail should always exist if there is a head.'
        + 'Only a bug somewhere else in the class will cause this error',
      );
    }

    this.#tail.next = node;
    this.#tail = node;

    return node;
  }

  public insertAt(position: number, value: T): ListNode<T> | null  {
    if (this.#size === 0 || position >= this.#size) {
      return this.insertFront(value);
    }

    let currentPosition = 0;

    for (const item of this) {
      if (currentPosition < position - 1) {
        currentPosition += 1;

        // eslint-disable-next-line no-continue
        continue;
      }

      return this.insertAfter(item, value);
    }

    return null;
  }

  public insertFront(value: T) {
    const node = createNode(value);

    this.#size += 1;

    if (!this.#head) {
      this.#head = node;
      this.#tail = node;

      return node;
    }

    node.next = this.#head;
    this.#head = node;

    return node;
  }

  public insertAfter(refNode: ListNode<T>, value: T) {
    const node = createNode(value, refNode.next);

    // eslint-disable-next-line no-param-reassign
    refNode.next = node;
    this.#size += 1;

    return node;
  }

  public getNode(position: number): ListNode<T> | null {
    if (!this.#head) {
      return null;
    }

    if (position === 0) {
      return this.#head;
    }

    if (position === this.#size - 1) {
      return this.#tail;
    }

    let currentPosition = 0;

    for (const item of this) {
      if (currentPosition < position) {
        currentPosition += 1;

        // eslint-disable-next-line no-continue
        continue;
      }

      return item;
    }

    return null;
  }

  public at(position: number): T | undefined {
    return this.getNode(position)?.value;
  }

  public removeHead(): T | undefined {
    if (!this.#head) return undefined;

    const head = this.#head;
    this.#head = this.#head.next;

    this.#size -= 1;

    if (!this.#size) {
      this.#tail = null;
    }

    return head.value;
  }

  public clear(): void {
    this.#head = null;
    this.#size = 0;
  }

  public toArray(): T[] {
    return [...this].map((node) => node.value);
  }

  public get size(): number {
    return this.#size;
  }

  // eslint-disable-next-line class-methods-use-this
  public get [Symbol.toStringTag]() {
    return 'LinkedList';
  }

  public [Symbol.iterator](): Iterator<ListNode<T>> {
    let item = this.#head;
    let done = item === null;

    return {
      next() {
        const obj = {
          value: item || null,
          done,
        } as IteratorResult<ListNode<T>>;

        item = item?.next || null;

        if (!item) done = true;

        return obj;
      },
    };
  }
}

export default LinkedList;
