import { isArray } from './assertion';

export const defineAction = <ActionHandle, ActionType>(
  type: ActionHandle,
  dispatch: React.Dispatch<ActionType>,
) => <PayloadType, >(payload?: ExtractProp<PayloadType, ActionType, 'payload'>): void => {
  dispatch({ type, payload } as unknown as ActionType);
};

export const invalidate = (fn: (...args: unknown[]) => void) => {
  let id: number | null = null;

  return (...args: unknown[]) => {
    window.cancelAnimationFrame(id as number);

    id = window.requestAnimationFrame(() => {
      fn(...args);
    });
  };
};

export const updateList = <T, >(value: T | T[], currentList: T[]) => {
  if (isArray(value)) {
    return value.flatMap((nestedValue) => {
      return currentList.includes(nestedValue)
        ? currentList.filter((item) => item !== nestedValue)
        : [...currentList, nestedValue];
    });
  }

  return currentList.includes(value)
    ? currentList.filter((item) => item !== value)
    : [...currentList, value];
};

// function updateList<T, >(value: T | T[], currentList: T[]) {
//   return currentList.includes(value)
//     ? currentList.filter((item) => item !== value)
//     : [...currentList, value];
// };
