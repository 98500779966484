import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';

import { AppStateContext } from '../../store/AppStateProvider';
import FormStateProvider from '../../store/FormStateProvider';
import Transition from '../Transition';
import FormHandler from '../FormHandler';
import FormSummary from '../FormSummary';
import HistoryNavigation from '../HistoryNavigation';

import transform, { f } from '../../utils/cloudinary-transforms';
import { StepTypes } from '../../types';

import './app.css';

const App = () => {
  const { currentStep } = useContext(AppStateContext);
  const { src, alt } = currentStep?.image || {};

  const appRef = useRef<HTMLDivElement>(null);

  const toggleGridColumns = useCallback(() => {
    setTimeout(() => {
      if (!appRef?.current) return;

      appRef.current.classList
        .toggle('App__wrapper--with-image', !!src || currentStep?.type === StepTypes.CONTACT);
    }, 500);
  }, [src, currentStep?.type]);

  useEffect(() => {
    toggleGridColumns();
  }, [toggleGridColumns]);

  return currentStep
    ? (
      <>
        <div
          ref={ appRef }
          className="App__wrapper"
        >
          <FormStateProvider>
            <div className="App__form-wrapper">
              <FormHandler onAfterLeave={ toggleGridColumns } />
            </div>
            <Transition
              name="slide-in"
              condition={ currentStep?.image }
              enterDelay={ 500 }
            >
              <div className="App__image-wrapper">
                <img
                  src={ src ? transform(src, f.appImage, true) : undefined }
                  alt={ alt }
                />
              </div>
            </Transition>
            <Transition
              name="slide-in"
              condition={ currentStep?.type === StepTypes.CONTACT }
              enterDelay={ 500 }
            >
              <div className="App__summary-wrapper">
                <FormSummary />
              </div>
            </Transition>
          </FormStateProvider>
        </div>
      </>
    )
    : null;
};

export default App;
