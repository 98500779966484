import React, { useCallback, useContext } from 'react';
import { FormStateContext } from '../../store/FormStateProvider';
import { conditionalArray } from '../../utils/misc';
import FormFieldset from '../FormFieldset';
import FormInputOption from '../FormInputOption';

import './form-checkbox-group.css';

const FormCheckboxGroup = (props: any) => {
  const {
    legend,
    options,
    name,
    searchParam,
    summaryLabel,
    value,
    onChange,
  } = props;

  const handleChangeEvent = useCallback((
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    onChange({
      name,
      label: legend,
      // pipedriveField,
      searchParam,
      summaryLabel,
      // type,
      value: event.target.value,
    });
  }, [name, legend, searchParam, summaryLabel, onChange]);

  const classList = conditionalArray(
    ['FormCheckboxGroup grid', true],
    ['FormCheckboxGroup--with-images', options?.[0]?.image],
  ).join(' ');

  return (
    <div className={ classList }>
      {
        options.map((option: any, index: number) => (
          <FormInputOption
            key={ index }
            type="checkbox"
            isChecked={ value?.includes(option.value) }
            onChange={ handleChangeEvent }
            { ...option }
          />
        ))
      }
    </div>
  );
};

export default FormFieldset(FormCheckboxGroup);
