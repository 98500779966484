import React, {
  useState,
  useCallback,
  ChangeEvent,
  useEffect,
} from 'react';

import { LeftRightArrows } from '../Icons';
import { clamp } from '../../utils/misc';

import './widget-slider.css';

const WidgetSlider = (props: any) => {
  const {
    min,
    max,
    step,
    value = min,
    markerFormatter,
    onChange,
  } = props;

  const [sliderValue, setSliderValue] = useState(0);
  const [sliderPercent, setSliderPercent] = useState(0);

  const handleChange = useCallback((event: ChangeEvent) => {
    const value = Number((event.target as HTMLInputElement).value);

    // const percent = 100 * ((value - min) / (max - min));

    // setSliderPercent(percent);
    // setSliderValue(value);

    onChange(value);
  }, [onChange]);

  useEffect(() => {
    if (!value) return;

    setSliderValue(value);
  }, [value]);

  useEffect(() => {
    const percent = 100 * ((sliderValue - min) / (max - min));

    setSliderPercent(clamp(percent, 0, 100));
  }, [min, max, sliderValue]);

  const cssVars: Record<string, string> = {
    '--budget-slider-percent': `${sliderPercent}%`,
  };

  const [minMarker, maxMarker] = markerFormatter(min, max);

  return (
    <div
      className="WidgetSlider"
      style={ cssVars }
    >
      <div className="WidgetSlider__slider">
        <input
          type="range"
          min={ min }
          max={ max }
          step={ step }
          value={ sliderValue }
          onChange={ handleChange }
          aria-valuemin={ min }
          aria-valuemax={ max }
          aria-valuenow={ sliderValue }
          className="WidgetSlider__input"
        />
        <div className="WidgetSlider__left-right-arrows">
          <LeftRightArrows />
        </div>
      </div>
      <div className="WidgetSlider__range-markers">
        <span className="text-h3 color-grey-darker">
          { minMarker }
        </span>
        <span className="text-h3 color-grey-darker">
          { maxMarker }
        </span>
      </div>
    </div>
  );
};

export default WidgetSlider;
